import styled, { css } from 'styled-components';
import { TextField } from '../Input/styles';

export const Container = styled.div`
  justify-content: center;
`;

export const PixImage = styled.img`
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 1.6rem;
`;

export const ButtonCopy = styled.button`
  border-radius: 12px;
  cursor: pointer;
  border: none;
  background: var(--BlueDream, linear-gradient(182deg, #00E7FF 5.08%, #0089FF 98.14%));
  border-radius: 16px;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  border-radius: 21px;
  color: white;
  margin-bottom: 1em;
  transition: 0.3s;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const PixCode = styled.div`
  text-align: center;
  font-size: 1.4rem;
  word-wrap: break-word;
  margin: 1rem 0;
  color: #3080af;
  cursor: pointer;
  border-radius: 13px;
  padding: 1.4rem 1.4rem;
`;

export const Choose = styled.div`
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  margin-bottom: 4rem;
`;

export const ChooseBg = styled.div`
border-radius: 12px;
background: var(--BlueDream, linear-gradient(182deg, #00E7FF 5.08%, #0089FF 98.14%));
  border-radius: 16px;
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 21px;


  margin-bottom: 1em;
  transition: 0.3s;
`;

export const ChooseTitle = styled.div`
  color: #2c9eca;
  font-size: 1.6rem;
`;

export const QrCodeContainer = styled.div`
  text-align: center;
  max-width: 90%;
  overflow: wrap;
`;

export const ChooseItem = styled.div`
  ${({ active }) => css`
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    align-items: center;

    cursor: pointer;
    gap: 0.5rem;

    ${!active &&
    css`
      ${ChooseBg} {
#fffborder-radius: 12px;
background: var(--BlurStroke, radial-gradient(302.54% 163.59% at 0% 0%, rgba(255, 255, 255, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%));
        transition: 0.3s;
      }

      ${ChooseTitle} {
        color: #7b7b7b;
      }
    `}
  `}
`;

export const CardStatic = styled.div`
  ${({ isFlipped }) => css`
    width: 34.3rem;
    height: 19.1rem;
    margin-bottom: 4rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%) ${isFlipped ? 'rotateY(180deg)' : 'rotateY(0)'};
    background-color: transparent;
    transform-style: preserve-3d; // Preserva o estilo 3D
    transition: transform 0.6s; // Suaviza a transformação
    perspective: 1000px; // Adiciona perspectiva ao efeito
  `}
`;

export const CardStaticFront = styled.div`
  background-image: url('/images/backgrounds/card-front.svg');
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;

  backface-visibility: hidden; // Esconde a parte traseira
`;
export const CardStaticBack = styled.div`
  background-image: url('/images/backgrounds/card-back.svg');
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: cover;

  backface-visibility: hidden; // Esconde a parte traseira
  transform: rotateY(180deg); // Rotaciona o lado de trás para que comece virado
`;

export const CardNumber = styled.div`
  color: #ffffff;
  font-size: 1.4rem;
  letter-spacing: 0.3rem;
  position: absolute;
  bottom: 5rem;
  left: 2.5rem;
`;
export const CardName = styled.div`
  color: #ffffff;
  font-size: 1.8rem;
  bottom: 2.3rem;
  left: 2.5rem;
  position: absolute;
`;
export const CardCvv = styled.div`
  color: #7b7b7b;
  font-size: 1.4rem;

  position: absolute;
  bottom: 6.5rem;
  right: 5.3rem;
  text-align: right;
`;

export const CardExpiryDate = styled.div`
  color: #fff;
  font-size: 1.4rem;

  position: absolute;
  bottom: 3.5rem;
  right: 5.3rem;
  text-align: right;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;

  ${TextField} {
    border-radius: 1.6rem;
  }
`;

export const Row = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const Group = styled.div``;

export const Disclaimer = styled.div`
  text-align: center;
  color: #3080af;
  font-weight: 1.4rem;
  margin: 2rem auto;
  font-size: 1.8rem;
  line-height: 2rem;
`;

export const ChooseIcon = styled.img.attrs(({ icon }) => ({
  src: `/images/icon/${icon}.svg`,
}))`
  ${({ width, height }) => css`
    width: ${width};
    height: ${height};
  `}
`;

export const ClickToCopy = styled.div`
  display: flex;
  width: fit-content;
  margin: 1em auto 0;
  padding: 0.8rem 1.4rem;
  border-radius: 51px;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.6rem;
  color: #809fb8;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #809fb8;

  &:hover {
    background-color: #f2f8fc;
  }
`;


export const QRCode = styled.div`
  /* Estilos do QRCode */
`;
