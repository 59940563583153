import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { httpService } from "../services/axios";
import Swal from "sweetalert2";

const DEFAULT_VALUES = {
    cart: [],
    loading: false,
    total: 0,
    maxVolume: 0,
    volumeSelected: 0
};

const cartStore = create(
    persist(
        (set, get) => ({
            cart: [],
            maxVolume: 0,
            volumeSelected: 0,
            loading: false,
            setMaxVolume: (maxVolume) => set({ maxVolume }),
            setCart: (cart) => {
                set({ cart });
                get().refreshPrice();
            },
            updateQuantity: (product, quantity) => {
                set((state) => {
                    const existingProduct = state.cart.find(
                        (p) => p.id === product.id
                    );
                    if (existingProduct) {
                        console.log("32?? state.volumeSelected::", state.volumeSelected)
                        console.log("33?? product.volume::", product.volume)
                        console.log("34?? quantity::", quantity)
                        const volume = state.volumeSelected + (product.volume * quantity) - (product.volume * existingProduct.quantity);
                        console.log(volume, state.maxVolume)
                        if(volume > state?.maxVolume) {
                            Swal.fire("Oops!", `Você atingiu a quantidade máxima de volume ${state.maxVolume}ml para este produto.`, "warning");
                            return state
                        };
                        
                        existingProduct.quantity = quantity
                        
                        return { cart: [...state.cart], volumeSelected: volume};
                    }
                    return state;
                });
                get().refreshPrice();
            },
            addToCart: (product) => {
                set((state) => {
                    const existingProduct = state.cart.find(
                        (p) => p.id === product.id
                    );
                    if (existingProduct) {
                        const volume = state.volumeSelected + product.volume;
                        if(volume > state?.maxVolume) {
                            Swal.fire("Oops!", `Você atingiu a quantidade máxima de volume ${state.maxVolume}ml para este produto.`, "warning");
                            return state
                        };
                        
                        existingProduct.quantity += 1;
                        
                        return { cart: [...state.cart], volumeSelected: volume};
                    }

                    if(state.volumeSelected + product.volume > state?.maxVolume) {
                        Swal.fire("Oops!", `Você atingiu a quantidade máxima de volume ${state.maxVolume}ml para este produto.`, "warning");
                        return state
                    }
                    console.log("66?? state.volumeSelected::", state.volumeSelected)
                    console.log("67?? product.volume::", product.volume)
                    console.log("68?? state.volumeSelected + product.volume::", state.volumeSelected + product.volume)
                    return {
                        cart: [...state.cart, { ...product, quantity: 1 }],
                        volumeSelected: state.volumeSelected + product.volume
                    };
                });
                get().refreshPrice();
            },
            removeFromCart: (product) => {
                set((state) => {
                    return {
                        cart: state.cart.filter((p) => p.id !== product.id),
                        volumeSelected: state.volumeSelected - (product.quantity * product.volume)
                    }
                });
                get().refreshPrice();
            },
            clearCart: () => {
                set({ ...DEFAULT_VALUES });
            },
            countItems: () => get().cart.length,
            total: 0,
            setTotal: (total) => set({ total }),
            refreshPrice: async () => {
                set({ loading: true });
                const { data } = await httpService.post(
                    `/calculate-shopping-cart`,
                    {
                        cart: get().cart,
                    }
                );
                set({ total: data?.total || 0 });
                set({ loading: false });
            },
        }),
        {
            name: "shoppingcart-store",
            storage: createJSONStorage(() => sessionStorage),
        }
    )
);

export { cartStore };
