import { useCallback, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { PROVIDER_NAME, TOKEN_NAME } from '../../env'
import { httpService } from '../../services/axios'

export const useSharedToken = () => {
  const navigate = useNavigate()
  const { sharedToken } = useParams()

  const handleGetJwt = useCallback(async () => {
    const { data } = await httpService.post('/auth/shared', {
      sharedToken
    })

    const { jwt } = data
    localStorage.setItem(TOKEN_NAME, jwt);
    localStorage.setItem(PROVIDER_NAME, 'local');

    navigate('/profile')
  }, [sharedToken])

  useEffect(() => {
    if (sharedToken) {
      handleGetJwt()
    }
  }, [sharedToken])
}