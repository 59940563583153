// Summary.js
import { cartStore } from "../../store/cart.store";
import Button from "../Button";
import * as S from "./styles";
import { formatCurrency } from '../../utils/formatCurrency';
import { useNavigate } from "react-router";

const Summary = ({ cep, endereco, frete, order }) => {
    const navigate = useNavigate();
    const statusPayment = { 
        pending_payment: 'Pendente',
        payment_approved: 'Aprovado',
        processing: 'Processando',
        accept: 'Aceito',
        recused: 'Recusado',
        shipping: 'Enviando',
        delivered: 'Entregue',
    }

    return (
        <S.SummaryContainer>
            {order.products.length > 0 && order.products.map((product) => (
                <S.ProductItem key={product.id}>
                    <S.ProductInfo>
                        <S.ProductDetails>
                            <S.ProductName>
                                {product.productId.data.attributes.title}
                                <S.ProductQuantity>
                                    x {product.quantity}
                                </S.ProductQuantity>
                            </S.ProductName>
                            <S.ProductPrice>
                                {formatCurrency(product.price)}
                            </S.ProductPrice>
                        </S.ProductDetails>
                    </S.ProductInfo>
                </S.ProductItem>
            ))}
            <S.SummaryFooter>
                <S.Row>
                    <S.Text>Produtos ({order.products.length})</S.Text>
                    <S.Text>{formatCurrency(order.totalProducts)}</S.Text>
                </S.Row>
                <S.Row>
                    <S.Text>Envio</S.Text>
                    <S.Text>{formatCurrency(120)}</S.Text>
                </S.Row>
                <S.Row>
                    <S.Text>Total</S.Text>
                    <S.Text>{formatCurrency(order.value)}</S.Text>
                </S.Row>
                <S.Row>
                    <S.Text>CEP</S.Text>
                    <S.Text>{order.sendTo.cep}</S.Text>
                </S.Row>
                <S.Row>
                    <S.Text>Status pagamento</S.Text>
                    <S.Text>{statusPayment[order.status]}</S.Text>
                </S.Row>
                <S.Row>
                    <S.Text>{`${order?.sendTo?.street}, ${order?.sendTo?.number}, ${order?.sendTo?.neighborhood}, ${order?.sendTo?.city}-${order?.sendTo?.state}`}</S.Text>
                </S.Row>
            </S.SummaryFooter>
            {order.status == 'pending_payment' && 
                <S.ContainerButton>
                    <S.ButtonCopy type="button" onClick={() => {
                        navigate(`/payment/${order.id}`)
                    }}>
                        Ir para pagamento
                    </S.ButtonCopy>
                </S.ContainerButton>
            }
        </S.SummaryContainer>
    );
};

export default Summary;
