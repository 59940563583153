import Webcam from 'react-webcam';
import { useCallback, useEffect, useState } from 'react';
import { useSprings } from 'react-spring';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { useFaceDetection } from 'react-use-face-detection';
import FaceDetection from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
import * as S from './styles'
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';

const width = "100vw";
const height = "100vh";

const randomProps = () => ({
  from: { left: '0', top: '0' },
  to: { left: `${Math.random() * (Math.random() * 150)}%`, top: `${Math.random() * 100}%` },
  config: { duration: 500 },
});

const Particles = ({ count = 10 }) => {
  const springs = useSprings(count, new Array(count).fill().map(() => randomProps()));

  return springs.map((props, index) => <S.Particles key={index} style={props} />);
};


const steps = (100 / 3)

const FaceDetectionPage = () => {
  const [percentage, setPercentage] = useState(0)
  const navigate = useNavigate()

  const handleGoToTermsAndConditions = () => {
    navigate('/terms-and-conditions')
  }

  const handlePercentage = useCallback(() => {
    if (percentage >= 1) return
    setTimeout(() => {
      let currentPercentage = (((percentage * 100) + steps) / 100).toFixed(2)

      if (currentPercentage === "0.99") currentPercentage = "1"
      setPercentage(currentPercentage)

    }, 2000)
  }, [percentage, steps])

  useEffect(() => {
    handlePercentage()
  }, [percentage])


  const { webcamRef, boundingBox, isLoading, detected, facesDetected } = useFaceDetection({
    faceDetectionOptions: {
      model: 'short',
    },
    faceDetection: new FaceDetection.FaceDetection({
      locateFile: (file) => {

        return `https:/cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`
      },
    }),
    camera: ({ mediaSrc, onFrame }) =>
      new Camera(mediaSrc, {
        onFrame,
        width,
        height,
      }),
  });

  return (
    <S.Container>
      {
        percentage < "1" && (
          <S.BoxTop>
            {percentage <= "0.33" && (
              <>
                <S.Icon src="/images/bluemed/recongnized-icon.svg" />
                Reconhecendo...
              </>
            )}
            {percentage > "0.33" && percentage <= "0.66" && "últimos detalhes..."}
          </S.BoxTop>
        )
      }
      <Webcam
        ref={webcamRef}
        forceScreenshotSourceSize
        style={{
          height,
          width,
          position: 'absolute',
          objectFit: 'cover'
        }}
      />

      <S.MaskFace>

        {
          percentage === "1" && (
            <S.Confirmed>
              <S.Icon src="/images/bluemed/feather-check-1.svg" style={{ width: "100px"}} />

              Confirmado
            </S.Confirmed>
          )
        }
        {
          percentage !== "1" && (
            <S.Scan>
              <S.ScanTop />
              <Particles count={10} />
              <S.ScanBottom />
            </S.Scan>
          )
        }
      </S.MaskFace>

      <S.BoxBottom completed={percentage === "1"}>
        <S.Loader completed={percentage === "1"}>
          <CircularProgressbar value={percentage} maxValue={1} text={`${percentage * 100}%`} styles={buildStyles({
            textColor: '#fff',
            textSize: '20px'
          })} />
        </S.Loader>

        {
          percentage === "1" && (
            <S.Button onClick={handleGoToTermsAndConditions}>
              <S.Icon src="/images/bluemed/term-and-condition-icon.svg"/>
              <Button icon="right">
                Termos & Condições
              </Button>
            </S.Button>
          )
        }
      </S.BoxBottom>
    </S.Container>
  )
}

export default FaceDetectionPage
