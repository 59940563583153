import styled, { css } from 'styled-components';

export const Container = styled.button`
  ${({ theme, block, variant, size }) => css`
    border: none;
    width: 100%;
    height: auto;

    font-family: ${theme.font.bold};
    border-radius: 120px;
    padding: 2rem 2.5rem;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
    &:active {
      opacity: 0.7;
      transition: ${theme.transition};
    }
    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    ${block &&
    css`
      width: 100%;
    `}

    ${size === 'large' &&
    css`
      font-size: 18px;

      width: 100%;
      min-height: 60px;
    `}

    ${variant === 'grey' &&
    css`
      border-radius: 17px;
      background: var(
        --BlurStroke,
        radial-gradient(
          302.54% 163.59% at 0% 0%,
          rgba(255, 255, 255, 0.3) 0%,
          rgba(255, 255, 255, 0) 100%
        )
      );
      height: 50px;
    `}

    ${variant === 'orders' &&
    css`
      border-radius: 17px;
      margin-bottom: 1rem;
      background: var(
        --BlurStroke,
        radial-gradient(
          302.54% 163.59% at 0% 0%,
          rgba(255, 255, 255, 0.3) 0%,
          rgba(255, 255, 255, 0) 100%
        )
      );
      height: 50px;
    `}

    ${variant === 'outline' &&
    css`
      background: none;
      border: 0.2rem solid #2b90c1;
      color: #2b90c1;
    `}

    ${variant === 'whiteBackground' &&
    css`
      background: #000000; /* White background */
      color: #808080; /* Grey text */
      border: 1px solid #151515; /* Slight border for definition */
      padding: 1rem 2rem;
      min-height: 60px;
      &:hover {
        background: #000; /* Slightly darker on hover */
      }
    `}

    ${variant === 'fitBlurBagkround' &&
    css`
      width: 100%;
      background: #fffff30 !important;
      color: #fff;
      font-family: 'PF Handbook Pro';
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    `}


    ${variant === 'blue' &&
    css`
      border-radius: 25px;
      background: var(
        --BlueDream,
        linear-gradient(182deg, #00e7ff 5.08%, #0089ff 98.14%)
      );
      color: #fff; /* Grey text */
      border: 1px solid #151515; /* Slight border for definition */
      padding: 1rem 2rem;
      min-height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &:hover {
      }
    `}


    ${variant === 'login' &&
    css`
      background: transparent linear-gradient(180deg, #101010 0%, #151515 100%)
        0% 0% no-repeat padding-box;
      color: #fff; /* Grey text */
      border: 1px solid #ffffff30; /* Slight border for definition */
      padding: 1rem 2rem;
      min-height: 60px;
      display: flex;
      width: 60%;

      &:hover {
        background: #000; /* Slightly darker on hover */
      }
    `}




    ${size === 'small' &&
    css`
      font-size: 16px;
      padding: 1rem 2rem;
    `}

    ${size === 'medium' &&
    css`
      font-size: 18px;
    `}
  `}
`;

export const IconWrapper = styled.div`
  color: ${({ iconColor }) => iconColor};
  color: white;
  font-size: 25px;
  line-height: 17px;
`;

export const Icon = styled.img.attrs(({ icon, iconColor, iconSrc }) => ({
  src: iconSrc || `/images/icon/arrow-${icon}-${iconColor}.svg`,
  alt: `Arrow ${icon}`,
}))``;
