import styled from 'styled-components';

export const ClearButton = styled.button`
  background-color: transparent;
  border-radius: 8px;
  padding: 0.6em;
  color: #ffffff40;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  border: none;

  svg {
    margin-right: 0 !important;
    font-size: 14px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 2.4rem 9rem;
  gap: 1rem;
`;

export const StickyResume = styled.div`
  position: sticky;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;

  background-color: rgb(3 6 20);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 50;
`;

export const Total = styled.div`
  color: #fff;
  font-size: 1.6rem;
`;

export const Highlight = styled.span`
  color: #28e8fc;
  font-size: 2.5rem;
`;

export const StickyResumeTitle = styled.p`
  color: #060814;
  font-weight: bold;
  background-color: #abf7ff;
  font-size: 16px;
  display: flex;
  border-radius: 38px;
  align-items: center;
  padding: 0.6em 1em;
  gap: 1em;
`;

export const AddOneButton = styled.button`
  background-color: #000;
  border-radius: 8px;
  padding: 0.6em;
  color: #ffffff40;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  border: none;
`;

export const CartItems = styled.div`
  color: #fff;
  font-size: 1.6rem;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
`;

export const Listing = styled.li`
  list-style: none;
  padding: 0;
  background: rgb(28, 36, 38);
  background: linear-gradient(
    0deg,
    rgba(28, 36, 38, 0) 0%,
    rgba(27, 36, 38, 1) 87%
  );
  width: 100%;
  align-items: center;
  margin: 0;
  padding: 1rem 4rem;
  display: flex;

  svg {
    font-size: 2rem;
    margin-right: 1rem;
  }
`;

export const InlineContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 4rem;
  width: 100%;
  gap: 1rem;
`;

export const ProductName = styled.span`
  font-weight: bold;
  color: #fff;
  border-radius: 8px;
  width: 100%;
`;

export const ProductBrand = styled.span`
  color: #fff;
  width: 100%;
`;

export const ProductPrice = styled.span`
  color: #fff;
  color: #28e8fc;
`;

export const Ulist = styled.ul`
  padding: 0;
  margin: 0;
  width: 100%;
`;

export const DiscountBox = styled.span`
  color: #28e8fc;
  font-size: 1rem;
  padding: 0.5rem;
`;

export const EmptyMessage = styled.p`
  color: #fff;
  font-size: 2rem;
  text-align: center;
`

export const Empty = styled.div`
  padding: 2rem 4rem;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
`

export const Anchor = styled.a`
  text-decoration: none;
`