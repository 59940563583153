// Payment.js

import { GoArrowLeft } from "react-icons/go";
import Header from "../../components/Header";
import Button from "../../components/Button";
import * as S from "./styles";

const PaymentSucessInfo = () => {
    return (
        <>
            <Header />
            <S.Container>
                <S.Title>
                    Muito bem, você de um mais um passo em busca da sua jornada
                    legal.
                </S.Title>
                <Button variant="blue">
                    <GoArrowLeft />
                    Voltar a página inicial
                </Button>
            </S.Container>
        </>
    );
};

export default PaymentSucessInfo;
