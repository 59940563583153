import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 1.5rem;
  height: calc(102vh - 150px - 275px);
  overflow-y: scroll;
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1.5rem;
`;

export const Items = styled.div`
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
  flex-direction: column;
`;

export const Item = styled.div`
  display: flex;
  gap: 2.5rem;
  align-items: center;
  width: 100%;
`;

export const ItemCover = styled.div`
     background-color: #262d2b;
    border-radius: 1.6rem;
    width: 8.3rem;
    height: 8.9rem;
    /* padding: 0.7rem 1.6rem; */
    overflow: hidden;

`;

export const ItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ItemWrap = styled.div`
  width: calc(100% - 8.3rem);

  display: flex;
  flex-direction: column;
  gap: 2.2rem;
`;

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemTitle = styled.h2`
  font-size: 1.3rem;
  font-weight: bold;
  color: #fff;
`;

export const ItemAction = styled.div`
  font-size: 1.3rem;
  color: #ff9c77;
  font-weight: bold;
`;

export const ItemPrice = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemLeft = styled.div``;

export const Select = styled.select`
  background-color: #262d2b;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  padding: 0.8rem 1.4rem;
  border-radius: 1.3rem;
  outline: none;
  background-image: url('/images/icon/arrow-bottom-blue.svg');
  background-repeat: no-repeat;
  padding-right: 2.5rem;
  background-position: 90% 1rem;
  appearance: none;
`;
export const Option = styled.option``;

export const ItemRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Discount = styled.p`
  font-size: 1rem;
  color: #28e8fc;
`;

export const Price = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
  color: #fff;
`;

export const EmptyCart = styled.div`
  max-width: 30rem;
  margin: 2rem auto;
  gap: 1rem;

  display: flex;
  flex-direction: column;

  button {
    justify-content: center;    
    text-align: center;
  }
`

export const EmptyCartText = styled.p`
  font-size: 2.5rem;
  color: #fff;
  margin-top: 2rem;
  text-align: center;
  
`