import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const openStyle = css`
  border-radius: 17px;
  background: var(
    --BlurStroke,
    radial-gradient(
      302.54% 163.59% at 0% 0%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    )
  );
`;

export const AccordionItem = styled.div`
  border-radius: 17px;
  background: var(
    --BlurStroke,
    radial-gradient(
      302.54% 163.59% at 0% 0%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    )
  );
  margin: 5px 0;
  padding: 2em 2em;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isActive }) => isActive && openStyle}
`;

export const AccordionTitle = styled.h2`
  font-size: 16px;
  color: #fff;
`;

export const Arrow = styled.span`
  border: solid #fff;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: ${({ isActive }) =>
    isActive ? 'rotate(-135deg)' : 'rotate(45deg)'};
  transition: transform 0.3s ease;
`;
export const AccordionContent = styled.div`
  max-height: 0;

  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  font-size: 18px;
  padding: 0 20px;
  color: #fff;
  border-radius: 17px;
  background: var(
    --BlurStroke,
    radial-gradient(
      302.54% 163.59% at 0% 0%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%
    )
  );
  border-radius: 12px;
  ${({ isActive }) =>
    isActive &&
    css`
      max-height: 1000px; // Use an appropriate max-height value
      padding: 1.5em 1.5em;
    `}
`;
