import { useState } from "react";
import { BsCartPlus } from "react-icons/bs";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import Button from "../Button";
import * as S from "./styles";
import { formatCurrency } from "../../utils/formatCurrency";

const Product = ({ product, addToCart }) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => setShow(!show);

  return (
    <S.Wrapper>
      <S.Container>
        <S.Top>
          <S.Figure>
            {product.medias.data && <S.Image src={product.medias.data[0].attributes.url} alt={product.title} />}
            {!product.medias.data && <S.Image src={'/images/products/oilimage.jpg'} alt={product.title} />}
          </S.Figure>
          <S.Info>
            <S.InlineContent>
              <S.InfoDescription>{product.category}</S.InfoDescription>
            </S.InlineContent>
            <S.InfoTitle>{product.title}</S.InfoTitle>
          </S.Info>
        </S.Top>
        <S.InlineContent>
          <S.PriceBox>
            <S.InfoPrice>{formatCurrency(product.price)}</S.InfoPrice>
          </S.PriceBox>
          <Button onClick={() => addToCart(product)} variant='blue'>
            <S.BuyLabel>
              Adicionar ao carrinho <BsCartPlus />
            </S.BuyLabel>
          </Button>
        </S.InlineContent>
        <S.Actions>
          <Button variant='outline' onClick={toggleShow}>
            Mais info {show ? <IoChevronUp color='#28E8FC' /> : <IoChevronDown color='#28E8FC' />}
          </Button>
        </S.Actions>
        {show && (
          <S.Detail>
            <S.ContentDescription>{product.description}</S.ContentDescription>
            {product.symptoms && (
              <S.SymptomsBox>
                <S.SymptomsTitle>Sintomas:</S.SymptomsTitle>
                <S.SymptomsList>
                  {(product.symptoms.split(',')).map((symptom, index) => (
                    <S.Symptom key={index}>{symptom}</S.Symptom>
                  ))}
                </S.SymptomsList>
              </S.SymptomsBox>
            )}
          </S.Detail>
        )}
      </S.Container>
    </S.Wrapper>
  );
};

export default Product;
