import styled from 'styled-components'

export const Container = styled.div`
padding: 1rem 3rem;
`

export const Action = styled.div`
  width: 80vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`