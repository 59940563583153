import { FaChevronLeft, FaUser } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import Logo from '../Logo';
import * as S from './styles';
import useMe from '../../hooks/useMe';

const Header = ({ showUser = true }) => {
  const navigate = useNavigate();
  const me = useMe();
  const { name } = me;
  return (
    <>
      <S.Header>
        <S.IconBox onClick={() => navigate(-1)}>
          <FaChevronLeft />
        </S.IconBox>
        <Logo height='2rem' type='bluepharm' />
      </S.Header>
      {
        showUser && (
          <S.UserHeader onClick={() => navigate('/account')}>
            <S.UserInfoContainer>
              <S.UserIconBox>
                <FaUser size={20} />
              </S.UserIconBox>
              <S.UserName>{name}</S.UserName>
            </S.UserInfoContainer>
          </S.UserHeader>
        )
      }
    </>
  );
};

export default Header;
