import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { HiOutlineDocumentArrowUp } from 'react-icons/hi2';

import { IoReturnUpBackOutline } from 'react-icons/io5';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { VIACEP } from '../../../../env';
import { httpService } from '../../../../services/axios';
import { debounce } from '../../../../utils/debounce';
import * as S from '../../styles';

import { useCallback, useEffect, useState } from 'react';
import { STEPS } from '../steps';
import { Step3Schema } from './schema';

const Step3 = ({ handleNext, loading, value, setValueStep }) => {
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({
    resolver: yupResolver(Step3Schema),
    defaultValues: value,
  });

  const { register, setValue } = methods;
  const errors = methods.formState.errors;

  const zipCode = methods.getValues().cep;

  const submit = useCallback(
    (values) => {
      setValueStep(values);
      handleNext(STEPS.STEP_4, { ...value, ...values });
    },
    [value],
  );

  const handleGetCep = useCallback(
    async (e) => {
      try {
        setIsLoading(true);
        const { data } = await httpService.get(VIACEP(e));
        const address = {
          street: data.logradouro,
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf,
          cep: data.cep,
        };

        setValue('street', data.logradouro);
        setValue('neighborhood', data.bairro);
        setValue('city', data.localidade);
        setValue('state', data.uf);
        setValue('cep', data.cep);
        setValueStep({
          ...address,
        });
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    },
    [setValue],
  );

  const debouncedHandleGetCep = debounce(handleGetCep, 1000); // 1000ms delay

  useEffect(() => {
    debouncedHandleGetCep(zipCode);
  }, [zipCode]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <S.Group>
          <S.TitleGroup>
            <b>3.</b> Endereço
          </S.TitleGroup>
          <Input
            styling='secondary'
            label='CEP'
            placeholder='CEP'
            name='cep'
            disable={isLoading}
            error={errors.cep?.message}
            mask='99999-999'
            onBlur={() => debouncedHandleGetCep(zipCode)}
            {...register('cep')}
            // onChange={(e) => {
            //   debouncedHandleGetCep(e.target.value);
            //   return e;
            // }}
          />

          <Input
            styling='secondary'
            label='Rua'
            placeholder='Rua'
            {...register('street')}
            name='street'
            disable={isLoading}
            error={errors.street?.message}
          />
          <S.InlineInput>
            <Input
              styling='secondary'
              label='Número'
              placeholder='Número'
              {...register('number')}
              name='number'
              disable={isLoading}
              maxLength={9}
              error={errors.number?.message}
            />

            <Input
              styling='secondary'
              label='Complemento'
              placeholder='Complemento'
              {...register('complement')}
              name='complement'
              disable={isLoading}
              error={errors.complement?.message}
            />
          </S.InlineInput>
          <Input
            styling='secondary'
            label='Bairro'
            placeholder='Bairro'
            {...register('neighborhood')}
            name='neighborhood'
            disable={isLoading}
            error={errors.neighborhood?.message}
          />

          <S.InlineInput>
            <Input
              styling='secondary'
              label='Cidade'
              placeholder='Cidade'
              {...register('city')}
              name='city'
              disable={isLoading}
              error={errors.city?.message}
            />

            <Input
              styling='secondary'
              label='Estado'
              placeholder='Estado'
              {...register('state')}
              name='state'
              disable={isLoading}
              error={errors.state?.message}
            />
          </S.InlineInput>

          <S.ButtonWrapper>
            <Button
              type='button'
              variant='whiteBackground'
              onClick={() => handleNext(2)}
            >
              {' '}
              <IoReturnUpBackOutline />
              Voltar
            </Button>
            <Button disabled={loading} variant='blue' type='submit'>
              Documento <HiOutlineDocumentArrowUp />
            </Button>
          </S.ButtonWrapper>
        </S.Group>
      </form>
    </FormProvider>
  );
};

export default Step3;
