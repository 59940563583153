import { useSharedToken } from "../../hooks/useSharedToken"

const AuthPage = () => {
  useSharedToken()

  return (
    <>Estamos te autenticando...</>
  )
}

export default AuthPage