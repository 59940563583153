import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
    
    @font-face {
      font-family: 'PF Handbook Pro Regular';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Regular'), url('/fonts/pf-handbook-pro/PFHandbookProRegular.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Italic';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Italic'), url('/fonts/pf-handbook-pro/PFHandbookProItalic.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Extra Thin';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Extra Thin'), url('/fonts/pf-handbook-pro/PFHandbookProExtraThin.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Extra Thin Italic';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Extra Thin Italic'), url('/fonts/pf-handbook-pro/PFHandbookProExtraThinItalic.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Thin';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Thin'), url('/fonts/pf-handbook-pro/PFHandbookProThin.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Thin Italic';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Thin Italic'), url('/fonts/pf-handbook-pro/PFHandbookProThinItalic.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Medium Italic';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Medium Italic'), url('/fonts/pf-handbook-pro/PFHandbookProMediumItalic.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Bold';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Bold'), url('/fonts/pf-handbook-pro/PFHandbookProBold.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Bold Italic';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Bold Italic'), url('/fonts/pf-handbook-pro/PFHandbookProBoldItalic.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Black';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Black'), url('/fonts/pf-handbook-pro/PFHandbookProBlack.woff') format('woff');
    }
    

    @font-face {
      font-family: 'PF Handbook Pro Black Italic';
      font-style: normal;
      font-weight: normal;
      src: local('PF Handbook Pro Black Italic'), url('/fonts/pf-handbook-pro/PFHandbookProBlackItalic.woff') format('woff');
    }

    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }
    
    html {
      font-size: 62.5%; // 1 rem = 10px; 10px/16px = 62.5%
    }
    
    body, html {
      font-family: 'PF Handbook Pro Regular', sans-serif;
    }
      
    body {
      background-color: ${theme.background};
      color: ${theme.text};
      margin: 0;
      padding: 0;  
    }

    .-no-overflow {
      overflow: hidden;
    }
  `}
`;
