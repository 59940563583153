import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import Swal from 'sweetalert2';
import Button from '../../../../components/Button';
import { httpService } from '../../../../services/axios';
import * as S from '../../styles';
import { Step4Schema } from './schema';

const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3MB em bytes

const Step4 = ({ value }) => {
  const inputFile = useRef(null);

  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [imageSource, setImageSource] = useState(null);
  const [file, setFile] = useState(null);

  const methods = useForm({
    resolver: yupResolver(Step4Schema),
    defaultValues: value,
  });

  const navigate = useNavigate();

  const { register, setValue } = methods;
  const errors = methods.formState.errors;

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        Swal.fire({
          title: 'Erro',
          text: 'A imagem é maior que 3MB',
          icon: 'error',
        });
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const base64String = e.target.result; // This is the base64-encoded string
        setValue('document', base64String);
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const handleUploadClick = () => {
    inputFile.current.click();
    setImageSource(null);

    setFile(inputFile.current.files[0]);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const transformBase64ToFile = (base64String, filename) => {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];

    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n) {
      u8arr[n - 1] = bstr.charCodeAt(n - 1);
      n -= 1; // to make eslint happy
    }

    return new File([u8arr], filename, { type: mime });
  };

  const isBase64 = (str) => {
    return typeof str === 'string' && str.includes(';base64,');
  };

  const submit = useCallback(async (data) => {
    const isBase64Document = isBase64(data.document);

    const file = isBase64Document
      ? transformBase64ToFile(data.document, 'document')
      : data.document;

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('document', file);
      formData.append('type', data.docType);

      await httpService.post(`/user/upload/document`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      Swal.fire({
        title: 'Upload feito com sucesso!',
        text: 'Precisamos apenas que você confirme sua conta, enviamos um código em seu email.',
        icon: 'success',
      }).then(() => {
        navigate(`/confirm-account?hash=${btoa(data.email)}`);
      });
    } catch (error) {
      Swal.fire({
        title: `Erro ao realizar o cadastro, tente novamente.`,
        icon: 'warning',
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const WebcamCapture = ({ setImageSource }) => (
    <S.Modal>
      <S.Mask />
      <Webcam
        audio={false}
        width='100%'
        screenshotFormat='image/jpeg'
        videoConstraints={{
          facingMode: 'environment',
        }}
      >
        {({ getScreenshot }) => (
          <S.ModalActions>
            <Button
              onClick={() => {
                const imageSrc = getScreenshot();

                const file = transformBase64ToFile(imageSrc, 'document');

                if (file.size > MAX_FILE_SIZE) {
                  Swal.fire({
                    title: 'Erro',
                    text: 'A imagem é maior que 3MB',
                    icon: 'error',
                  });
                  return;
                }

                setFile(null);
                setImageSource(imageSrc);
                toggleModal();
                if (imageSrc) {
                  setValue('document', imageSrc);
                }
              }}
            >
              Capturar foto
            </Button>

            <Button
              variant='outline'
              onClick={() => {
                toggleModal();
              }}
            >
              Fechar
            </Button>
          </S.ModalActions>
        )}
      </Webcam>
    </S.Modal>
  );

  return (
    <>
      {showModal && (
        <WebcamCapture
          setImageSource={setImageSource}
          toggleModal={toggleModal}
        />
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submit)}>
          <S.Group>
            <S.TitleGroup>
              <b>4.</b> Faça upload do seu documento
            </S.TitleGroup>

            <S.Document {...register('docType')}>
              <option value=''>Selecione um documento</option>
              <option value='rg'>RG</option>
              <option value='cpf'>CPF</option>
              <option value='cnh'>CNH</option>
              <option value='passport'>Passaporte</option>
            </S.Document>
            {errors.docType?.message}

            <S.UploadDocument>
              <S.UploadDocumentTop>
                <S.Icon name='document' />
                <S.UploadDocumentLabel>
                  Envie seu documento
                </S.UploadDocumentLabel>
              </S.UploadDocumentTop>
              <S.UploadDocumentExamples>
                RG, CPF, Passaporte...
              </S.UploadDocumentExamples>

              <S.UploadDocumentBottom>
                <S.UploadDocumentAction onClick={toggleModal}>
                  <S.Icon name='camera' />
                </S.UploadDocumentAction>
                <S.UploadDocumentAction onClick={handleUploadClick}>
                  <input
                    type='file'
                    ref={inputFile}
                    onChange={handleFileInputChange}
                    style={{ display: 'none' }}
                    accept='image/png, image/jpeg'
                  />
                  <S.Icon name='upload' />
                </S.UploadDocumentAction>
              </S.UploadDocumentBottom>

              {(file || imageSource) && (
                <S.UploadDocumentSelected>
                  {imageSource ? (
                    <>
                      <S.ImagePreview src={imageSource} />
                      Foto capturada com sucesso!
                    </>
                  ) : (
                    `Arquivo selecionado: ${file?.name}`
                  )}
                </S.UploadDocumentSelected>
              )}
              {errors?.document?.message && (
                <S.UploadDocumentSelected>
                  {errors?.document?.message}
                </S.UploadDocumentSelected>
              )}
            </S.UploadDocument>
            <S.ButtonWrapper>
              <Button onClick={() => navigate('/profile')}>Próximo</Button>
            </S.ButtonWrapper>
          </S.Group>
        </form>
      </FormProvider>
    </>
  );
};

export default Step4;
