import React, { useState } from 'react';
import { AiOutlineLogin } from 'react-icons/ai';
import { BsArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Login from '../../components/Login'; // Importe o componente de login
import * as S from './styles';

const Welcome = () => {
  const [showLogin, setShowLogin] = useState(false); // Estado para controlar a visibilidade do modal de login
  const navigate = useNavigate();

  const openLoginModal = () => {
    setShowLogin(true); // Abre o modal de login
  };

  return (
    <S.Container>
      <S.Heading>
        <S.Logo />
      </S.Heading>
      <S.Top>
         <S.Buddy />
      </S.Top>Você não possui produtos prescritos.


      <S.Action>
        <S.Middle>
          <S.Title>
            Dê mais uma passo em busca<br></br>da sua jornada legal
          </S.Title>
          <S.Subtitle>
            Com sua receita em mãos inicie o processo para a compra do seu
            produto
          </S.Subtitle>
        </S.Middle>

        <S.InlineButton>
          <Button block onClick={openLoginModal} variant='login'>
            Login <AiOutlineLogin />
          </Button>
          <Button
            block
            variant='blue'
            onClick={() => navigate('/register')}
            size='medium'
            icon='right'
          >
            Iniciar jornada
            <BsArrowRight />
          </Button>
        </S.InlineButton>
      </S.Action>

      {/* Renderiza o modal de login se showLogin for true */}
      {showLogin && (
        <div>
          <div className='overlay' onClick={() => setShowLogin(false)}></div>
          <div className='modal'>
            <Login show={showLogin} toggleLogin={() => setShowLogin(false)} loginRedirect="/profile"/>
          </div>
        </div>
      )}
    </S.Container>
  );
};

export default Welcome;
