import styled from 'styled-components';

export const Heading = styled.div`
  padding: 4rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: inherit;
`;

export const Top = styled.div`
  position: relative;
  height: 43rem;
`;

export const Ring = styled.img.attrs({
  src: '/images/bluemed/ring.svg',
})`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;



export const Buddy = styled.img.attrs({
  src: '/images/backgrounds/cbd-pot.png',
})`
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  width: 100%;
  max-width: 300px;
  height: auto;

  @media (min-width: 768px) {
    max-width: 600px;
  }
`;


export const Logo = styled.img.attrs({
  src: '/images/bluemed/logo-inline.svg',
})`
  height: 3rem;
`;

export const Middle = styled.div`
  text-align: center;
  color: #fff;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 3rem;
`;

export const Marker = styled.span``;

export const Title = styled.h1`
  font-size: 3.1rem;
  line-height: 1em;

  ${Marker} {
    color: #00e2ff;
  }
`;

export const Subtitle = styled.h2`
  font-weight: 300;
  line-height: 0.8;
  font-size: 2.5rem;

  ${Marker} {
    color: #5fdaff;
  }
`;

export const Action = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  padding: 0 2rem 3rem;
`;

export const AllRights = styled.p`
  font-size: 2rem;
  color: #fff;

  margin: auto auto 1.2rem;
`;

export const InlineButton = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;
