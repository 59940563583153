// Summary.js
import { cartStore } from "../../store/cart.store";
import { formatCurrency } from "../../utils/formatCurrency";
import * as S from "./styles";

const SummaryPayment = ({ cep, endereco, frete, amount }) => {
    const { cart, total } = cartStore();

    return (
        <S.SummaryContainer>
            <S.SummaryFooter>
                <S.Row>
                    <S.Text>Produtos ({cart.length})</S.Text>
                    <S.Text>{formatCurrency(total)}</S.Text>
                </S.Row>
                <S.Row>
                    <S.Text>Envio</S.Text>
                    <S.Text>{formatCurrency(frete ? frete : 0)}</S.Text>
                </S.Row>
                <S.Row>
                    <S.Text>Total</S.Text>
                    <S.Text>{formatCurrency(amount ? amount : 0)}</S.Text>
                </S.Row>
                <S.Row>
                    <S.Text>CEP</S.Text>
                    <S.Text>{cep}</S.Text>
                </S.Row>
                <S.Row>
                    <S.Text>{endereco}</S.Text>
                </S.Row>
            </S.SummaryFooter>
        </S.SummaryContainer>
    );
};

export default SummaryPayment;
