import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { FiLock, FiMail, FiUser } from 'react-icons/fi';
import Input from '../../../../../components/Input';
import * as S from '../../styles';
import { Step1Schema } from './schema';

const Step1 = ({ handleNext, loading, value, setValueStep }) => {
  const methods = useForm({
    resolver: yupResolver(Step1Schema),
    defaultValues: value
  });

  const errors = methods.formState.errors;

  const submit = useCallback(
    (values) => {
      handleNext({ ...value, ...values });
      setValueStep(values);
    },
    [value],
  );

  const handleChange = (fieldName, value) => {
    setValueStep({ ...value, [fieldName]: value });
    methods.setValue(fieldName, value);
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <S.Group>
        <Input
            styling='secondary'
            placeholder='Nome completo'
            label='Nome'
            name='name'
            value={value.name}
            onChange={(e) => handleChange('name', e.target.value)}
            error={errors.name?.message}
            icon={<FiUser size={20} />}
          />

          <Input
            styling='secondary'
            label='Email'
            placeholder='Email'
            name='email'
            value={value.email}
            onChange={(e) => handleChange('email', e.target.value)}
            error={errors.email?.message}
            icon={<FiMail size={20} />}
          />

          <Input
            styling='secondary'
            label='Senha'
            placeholder='senha'
            type='password'
            name='password'
            value={value.password}
            onChange={(e) => handleChange('password', e.target.value)}
            error={errors.password?.message}
            icon={<FiLock size={20} />}
          />

          <Input
            styling='secondary'
            label='Confirmar senha'
            value={value.confirmPassword}
            onChange={(e) => handleChange('confirmPassword', e.target.value)}
            placeholder='Confirmar senha'
            type='password'
            name='confirmPassword'
            error={errors.confirmPassword?.message}
            icon={<FiLock size={20} />}
          />

          <Input
            styling='secondary'
            label='(+55) 00 0 0000-0000'
            placeholder='(+55) 00 0 0000-0000'
            mask='+55 (99) 99999-9999'
            name='whatsapp'
            value={value.whatsapp}
            onChange={(e) => handleChange('whatsapp', e.target.value)}
            error={errors.whatsapp?.message}
            icon={<AiOutlineWhatsApp size={20} />}
          />

          <S.UpdateAccountButton isLoading={loading}>Atualizar cadastro</S.UpdateAccountButton>
        </S.Group>
      </form>
    </FormProvider>
  );
};

export default Step1;
