import * as S from './styles';

const Button = ({
  children,
  disabled,
  isLoading,
  isLoadingMessage,
  icon,
  iconColor = 'white',
  ...props
}) => {
  return (
    <S.Container disabled={disabled || isLoading} {...props}>
      {isLoading ? (
        <>
          {isLoadingMessage || 'Carregando...'} &nbsp;
          <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            width='20px'
            height='20px'
            viewBox='0 0 100 100'
            preserveAspectRatio='xMidYMid'
          >
            <circle
              cx='50'
              cy='50'
              fill='none'
              stroke='#fff'
              stroke-width='10'
              r='35'
              stroke-dasharray='164.93361431346415 56.97787143782138'
            >
              <animateTransform
                attributeName='transform'
                type='rotate'
                repeatCount='indefinite'
                dur='1s'
                values='0 50 50;360 50 50'
                keyTimes='0;1'
              ></animateTransform>
            </circle>
          </svg>
        </>
      ) : (
        children
      )}
    </S.Container>
  );
};

export default Button;
