import { forwardRef, useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import InputMask from 'react-input-mask';

import generateUUID from '../../utils/generateUUID';
import * as S from './styles';

const Input = forwardRef(
  (
    {
      styling = 'default',
      icon,
      label,
      placeholder,
      type = 'text',
      mask,
      error,
      ...props
    },
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword((prev) => !prev);

    const id = generateUUID();

    return (
      <S.Container styling={styling} icon={icon}>
        {styling === 'default' && <S.Label htmlFor={id}>{label}</S.Label>}

        <S.FieldWrapper>
          {mask && (
            <InputMask mask={mask} maskChar={' '} {...props}>
              {(inputProps) => (
                <S.TextField
                  placeholder={placeholder}
                  type={showPassword ? 'text' : type}
                  error={error}
                  id={id}
                  ref={ref}
                  {...inputProps}
                />
              )}
            </InputMask>
          )}

          {!mask && (
            <S.TextField
              placeholder={placeholder}
              type={showPassword ? 'text' : type}
              error={error}
              ref={ref}
              id={id}
              {...props}
            />
          )}

          {styling !== 'default' && <S.Label htmlFor={id}>{label}</S.Label>}

          <S.Icon>{icon}</S.Icon>

          {type === 'password' && (
            <S.ShowPassword onClick={toggleShowPassword}>
              {showPassword ? <FiEyeOff /> : <FiEye />}
            </S.ShowPassword>
          )}
        </S.FieldWrapper>

        {error && <S.Error>{error}</S.Error>}
      </S.Container>
    );
  },
);

export default Input;
