import { useEffect, useRef, useState } from 'react';
import { AiOutlineProduct } from 'react-icons/ai';
import { BsArrowRight } from 'react-icons/bs';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Product from '../Product';
import * as S from './styles';
import { formatCurrency } from '../../utils/formatCurrency';
import Swal from 'sweetalert2';
import { httpService } from '../../services/axios';
import { cartStore } from "../../store/cart.store";
import Button from '../Button';
import { REACT_APP_BLUEMED_FRONT } from '../../env';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { cart, clearCart, setMaxVolume, loading: loadingCart, addToCart, removeFromCart, total } =
    cartStore();

  const isFirstRender = useRef(true);


  useEffect(() => {
    async function fetchProducts() {
      try {
        setLoading(true);
        Swal.fire({
          icon: 'info',
          title: 'Carregando...',
          text: 'Buscando seus produtos prescritos.',
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        const { data: response } = await httpService.get('/products/prescripted');
        setProducts(response);

        if(response?.length) {
          setMaxVolume(response[0]?.quantity)
        }
        Swal.close();
      } catch (error) {
        setProducts([])   
        Swal.fire('Oops!', 'Não foi possível buscar seus produtos prescritos.', 'error');     
      } finally {
        setLoading(false);
      }
    }

    if (isFirstRender.current) {
      isFirstRender.current = false;

      fetchProducts();
    }
  }, [])

  useEffect(() => {
    if (!loading && !products?.length) {
      clearCart()
    }
  }, [products, loading])

  const handleCheckout = () => {
    if (cart.length === 0) {
      Swal.fire('Oops!', 'Parece que você não tem nenhum produto no carrinho, adicione produtos para finalizar a compra.', 'warning');
      return;
    }

    navigate('/cart'); // Alteração aqui
  };

  if(loading) {
    return <></>
  }

  return (
    <>
      {
        products?.length === 0 && (
          <S.Empty>
            <S.EmptyMessage>
              Você não possui produtos prescritos.
              <br />

              Acesse a Bluemed.cc para uma nova consulta com um especialista.
            </S.EmptyMessage>

            <S.Anchor href={REACT_APP_BLUEMED_FRONT}>
              <Button variant='blue'>Acessar a Bluemed.cc</Button>
            </S.Anchor>
          </S.Empty>
        )
      }

      {products?.length > 0 && <S.Container>
        {products?.map((product) => (
          <Product
            key={product.id}
            product={{ ...product.attributes, id: product.id, prescriptionId: product.prescriptionId, maxQuantity: product.quantity / product.attributes?.volume }}
            selected={false}
            addToCart={addToCart}
          />
        ))}
      </S.Container>}
      <S.StickyResume>
        {cart.length > 0 && (
          <S.CartItems>
            <S.Ulist>
              {cart.map((product) => (
                <S.Listing key={product.id}>
                  <AiOutlineProduct />
                  <S.ProductName>
                    {product.quantity} x {product.title} {product.category}
                  </S.ProductName>
                  <S.ProductPrice>
                    {formatCurrency(product.price)}
                  </S.ProductPrice>
                  <S.ClearButton onClick={() => removeFromCart(product)}>
                    <FaRegTrashAlt />
                  </S.ClearButton>
                </S.Listing>
              ))}
            </S.Ulist>
          </S.CartItems>
        )}

        {
          !!products?.length && (
            <S.InlineContent>
              <S.Total>
                Total: <br />
                <S.Highlight>{loadingCart ? 'Calculando preço' : formatCurrency(total)}</S.Highlight>
              </S.Total>

              <S.StickyResumeTitle onClick={handleCheckout}>
                Finalizar Compra <BsArrowRight />
              </S.StickyResumeTitle>
            </S.InlineContent>
          )
        }
      </S.StickyResume>
    </>
  );
};

export default Products;
