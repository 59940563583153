import styled from 'styled-components';

export const IconBox = styled.div`
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 1px;
  border-radius: 39px;
  background: #000;
  color: #dfedf4;
  border: 1px solid #dfedf4;

  img {
    transition: transform 0.3s ease; // Apply the transition to the transform property of the img
  }

  &:hover {
    border: 1px solid #dfedf4;
    background: #000;

    img {
      transform: translateX(-5px);
    }
  }
`;

export const LeftIcon = styled.img`
  width: 24px;
`;

export const Header = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 0;
  box-shadow: 0px 0px 30px 0px rgba(226, 236, 237, 0.1);
  padding: 2em 3em;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UserIconBox = styled.div`
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 1px;
  border-radius: 39px;
  background: #000;
  color: #dfedf4;
  border: 1px solid #dfedf4;

  img {
    transition: transform 0.3s ease; // Apply the transition to the transform property of the img
  }

  &:hover {
    border: 1px solid #dfedf4;
    background: #000;

    img {
      transform: translateX(-5px);
    }
  }
`;
export const Container = styled.div`
  border: 1px solid #959595;
  border-radius: 24px;
  background: #ffffff17 0% 0% no-repeat padding-box;
  padding: 0.4rem;
  max-width: 25rem;
  margin: 2em auto;

  display: flex;
`;
export const UserHeader = styled.div`
  border: 1px solid #959595;
  border-radius: 24px;
  background: #ffffff17 0% 0% no-repeat padding-box;
  padding: 0.4rem;
  max-width: 25rem;
  margin: 2em auto;

  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    transition: all 200ms;
  }
`;

export const UserInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const UserName = styled.span`
  font-size: 24px;
  color: #fff;
`;
