import { Helmet } from "react-helmet";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import routes from "./routes";

function App() {
    return (
        <Router>
            <Routes>
                {routes.map((route, index) => {
                    const Template = route.template;
                    const Page = route.page;
                    const title = route.title;

                    return (
                        <Route
                            key={index}
                            path={route.path}
                            element={
                                <Template isPublic={route.isPublic}>
                                    <Helmet title={`Blue Pharm - ${title}`} />
                                    <Page />
                                </Template>
                            }
                        />
                    );
                })}
            </Routes>
        </Router>
    );
}

export default App;
