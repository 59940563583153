import { useEffect, useState } from 'react';
import { AiOutlineProduct } from 'react-icons/ai';
import { LuUser2 } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import Header from '../../../components/Header';
import { httpService } from '../../../services/axios';
import * as S from './styles';
import Swal from 'sweetalert2';
const Orders = () => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    try {
      Swal.fire({
        icon: 'info',
        title: 'Carregando...',
        text: 'Estamos buscando seus produtos.',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        willOpen: () => {
            Swal.showLoading();
        },
      });

      const { data } = await httpService.get(`/orders/customer`);
      setOrders(data);

      Swal.close();

    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'info',
        title: 'Oops!',
        text: 'Ocorreu um erro ao resgatar os produtos, caso o erro persista entre em contato com o suporte.',
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/account`);
        }
      });
    }

  };

  const handleOpenOrderClick = (id) => {
    navigate(`/account/orders/${id}`);
  };

  return (
    <>
      <Header />
      <S.Container>
        <S.Title>
          <AiOutlineProduct />
          Meus Pedidos
        </S.Title>

        {orders && orders.length > 0 ? (
          orders.map((order) => (
            <Button
              key={order.id}
              variant='orders'
              size='large'
              block
              onClick={() => handleOpenOrderClick(order.id)}
            >
              Pedido #{order.id}
              <LuUser2 />
            </Button>
          ))
        ) : (
          <p>Nenhum pedido encontrado</p>
        )}
      </S.Container>
    </>
  );
};

export default Orders;
