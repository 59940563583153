import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { AiOutlineRight, AiOutlineWhatsApp } from 'react-icons/ai';
import { FiLock, FiMail, FiUser } from 'react-icons/fi';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import * as S from '../../styles';
import { STEPS } from '../steps';
import { Step1Schema } from './schema';

const Step1 = ({ handleNext, value, setValueStep }) => {
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(Step1Schema),
    defaultValues: value,
  });

  const errors = methods.formState.errors;

  const submit = (values) => {
    handleNext(STEPS.STEP_2);
    setValueStep(values);
  };

  const { register, getValues } = methods;

  return (
    <FormProvider {...methods}>
      <S.Form onSubmit={methods.handleSubmit(submit)}>
        <S.Group>
          <S.TitleGroup>
            <b>1.</b> Informações Principais
          </S.TitleGroup>

          <Input
            styling='secondary'
            placeholder='Nome completo'
            label='Nome'
            name='name'
            {...register('name')}
            error={errors.name?.message}
            icon={<FiUser size={20} />}
          />

          <Input
            styling='secondary'
            label='Email'
            placeholder='Email'
            name='email'
            {...register('email')}
            error={errors.email?.message}
            icon={<FiMail size={20} />}
          />

          <Input
            styling='secondary'
            label='Senha'
            {...register('password')}
            placeholder='senha'
            type='password'
            name='password'
            error={errors.password?.message}
            icon={<FiLock size={20} />}
          />

          <Input
            styling='secondary'
            label='Confirmar senha'
            {...register('confirmPassword')}
            placeholder='Confirmar senha'
            type='password'
            name='confirmPassword'
            error={errors.confirmPassword?.message}
            icon={<FiLock size={20} />}
          />

          <Input
            styling='secondary'
            label='(+55) 00 0 0000-0000'
            placeholder='(+55) 00 0 0000-0000'
            mask='+55 (99) 99999-9999'
            name='whatsapp'
            error={errors.whatsapp?.message}
            icon={<AiOutlineWhatsApp size={20} />}
            {...register('whatsapp')}
          />

          <Button variant='blue'>
            Próximo <AiOutlineRight />
          </Button>
        </S.Group>
      </S.Form>
    </FormProvider>
  );
};

export default Step1;
