// styles.js
import styled from 'styled-components';

export const SummaryContainer = styled.div`
border-radius: 12px;
    border-radius: 12px;
    background: var(--BlurStroke, radial-gradient(302.54% 163.59% at 0% 0%, rgb(9 20 25) 0%, rgb(7 13 41) 100%));
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;

  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

export const SummaryTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
  color: #fff;
`;

export const ProductItem = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ffffff10;
  padding: 10px 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonCopy = styled.button`
  border-radius: 12px;
  cursor: pointer;
  border: none;
  background: var(--BlueDream, linear-gradient(182deg, #00E7FF 5.08%, #0089FF 98.14%));
  border-radius: 16px;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  border-radius: 21px;
  color: white;
  margin-bottom: 1em;
  transition: 0.3s;
`;

export const ProductImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 12px; 
`;

export const ProductDetails = styled.div`
  flex: 1;
`;

export const ProductName = styled.p`
  font-size: 16px;
  display: flex;    
  color: #fff;
  gap: 1rem;
  align-items: center;
  margin-bottom: 5px;
`;

export const ProductPrice = styled.p`
  font-size: 14px;
  color: #fff;

`;

export const ProductQuantity = styled.p`
  font-size: 14px;
  color: #fff;
`;

export const SummaryFooter = styled.div`
  margin-top: 20px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  font-size: 16px;
  color: #fff;
`;

export const Input = styled.input`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  margin-right: 10px;
  flex: 1;

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

export const UploadButton = styled.label`
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const TextInput = styled.span`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;

export const ClickToCopy = styled.span`
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    color: #333;
  }
`;

export const Container = styled.div``;

export const Footer = styled.div`
  padding: 3em;
  background-color: #262d2b;
  border-radius: 30px 30px 0px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

// Restante do arquivo com os estilos...
