import { object, ref, string } from "yup";
import { httpService } from "../../../../services/axios";
import { API_URL_MIDDLEWARE } from "../../../../env";
import * as yup from "yup";

// Função debounce
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

// Verifica se o email já existe
async function isEmailTaken(email) {
  try {
    const response = await httpService.get(`${API_URL_MIDDLEWARE}/users/${email}`);
    return !!response.data;
  } catch (error) {
    console.error('Erro na verificação do email:', error);
    return false;
  }
}

// Utilize um factory para garantir que a função debounced seja única
function createDebouncedCheckEmail() {
  let lastEmail, lastResult;
  const checkEmail = async (email, callback) => {
    const isTaken = await isEmailTaken(email);
    lastEmail = email;
    lastResult = isTaken;
    callback(isTaken);
  };

  // Debounce na função de verificação
  const debouncedCheckEmail = debounce(checkEmail, 500);

  return async (email, callback) => {
    if (email === lastEmail) {
      callback(lastResult);
      return;
    }
    debouncedCheckEmail(email, callback);
  };
}

const debouncedCheckEmail = createDebouncedCheckEmail();

export const Step1Schema = object({
  email: string()
    .email("Insira um email válido")
    .max(100, "Email muito longo...")
    .test('email-exists', 'Este email já está cadastrado.', function(value) {
      return new Promise((resolve) => {
        debouncedCheckEmail(value, (isTaken) => {
          resolve(!isTaken);
        });
      });
    })
    .required("Oops, parece que você não adicionou um email..."),
  name: string()
    .max(150, "Nome muito longo...")
    .required("Oops, parece que você não adicionou um nome..."),
  password: yup
    .string()
    .min(5, "Humm, a senha está muito curta...")
    .max(30, "Senha muito longa...")
    .required("Oops, parece que você não adicionou a sua senha..."),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas não coincidem")
    .required("Você precisa confirmar a senha"),
  whatsapp: yup
    .string()
    .required(
      "Oops, parece que você não adicionou um número para o whatsapp..."
    ),
});
