import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const DocumentPosition = styled.div`
  width: 2.9rem;
  height: 2.9rem;
  border: 1px solid #fff;
  border-radius: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.7rem;
  font-weight: bold;

  padding-top: 3px;
  padding-right: 1px;
`;

export const DocumentTitle = styled.div`
  font-size: 1.7rem;
  font-weight: 600;
`;

export const DocumentAction = styled.div`
  margin-left: auto;
  margin-right: 0;
`;

export const DocumentButton = styled.div`
  padding: 0.7rem 1.5rem;
  background: transparent linear-gradient(180deg, #00e7ff 0%, #0089ff 100%) 0%
    0% no-repeat padding-box;
  border-radius: 11px;
  font-size: 1.3rem;

  display: flex;
  gap: 1rem;

  cursor: pointer;
`;

export const DocumentIcon = styled.img`
  height: 1.2rem;
`;

export const DocumentLogo = styled.img`
  height: 1.5rem;
`;

export const Disclaimer = styled.div`
  color: #d6d6d6;
  max-width: 20rem;
  margin: 0 auto;
  text-align: center;
`;

export const Document = styled.div`
  padding: 2.2rem 2rem;

  border-radius: 3.7rem;
  background-color: #303735;

  display: flex;
  align-items: center;

  color: #fff;
  gap: 2rem;
  opacity: 0.4;

  ${({ done }) =>
    done &&
    css`
      border: 1px solid #28e8fc;
      opacity: 1;

      ${DocumentPosition} {
        background: transparent
          linear-gradient(180deg, #00e7ff 0%, #0089ff 100%) 0% 0% no-repeat
          padding-box;
        color: transparent;
        border: none;

        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 11px;
          top: 7px;
          width: 5px;
          height: 10px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    `}

  ${({ current }) =>
    current &&
    css`
      opacity: 1;
    `}

  ${({ current, done }) =>
    !current &&
    !done &&
    css`
      ${DocumentButton} {
        background: #3d4442;
      }
    `}
`;

export const Products = styled.div`
  display: flex;
  flex-direction: column;
  padding: 9.5rem 2.4rem 4rem;
  gap: 8rem;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  margin: auto;
  padding: 2em 2rem;
`;
