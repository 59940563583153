import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Button from '../../components/Button';
import Header from '../../components/Header';
import { httpService } from '../../services/axios';
import * as S from './styles';

const TermsAndConditions = () => {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [term, setTerm] = useState(null);

  const handleGetTermInfo = async () => {
    try {
      setLoading(true);

      const { data } = await httpService.get('/contents/terms-and-conditions');

      setTerm(data.description);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetTermInfo();
  }, []);

  useEffect(() => {
    if (loading) {
      Swal.fire({
        title: 'Obtendo os termos e condições...',
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
    }

    if (!loading) {
      Swal.close();
    }
  }, [loading]);

  const navigate = useNavigate();

  const handleGoToProfile = () => {
    navigate('/profile');
  };

  const handleChecked = () => {
    setChecked(!checked);
  };

  return (
    <>
      <Header />
      <S.Container>
        <S.Title>
          Termos <S.Marker>&</S.Marker> <br /> Condições
        </S.Title>

        <S.Dots>
          <S.Dot />
          <S.Dot />
          <S.Dot />
        </S.Dots>

        <S.Content>
          <S.Paragraph>{term}</S.Paragraph>

          <S.Checkbox checked={checked}>
            <S.CheckboxInput
              type='checkbox'
              id='checkbox'
              onChange={handleChecked}
            />
            <S.CheckboxLabel>
              Eu aceito os <S.Marker>Termos & condições</S.Marker>
            </S.CheckboxLabel>
          </S.Checkbox>

          <S.Actions checked={checked}>
            <S.Icon />

            <Button
              icon='right'
              block
              disabled={!checked}
              onClick={handleGoToProfile}
            >
              Entrar no novo universo
            </Button>
          </S.Actions>
        </S.Content>
      </S.Container>
    </>
  );
};

export default TermsAndConditions;
