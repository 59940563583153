import styled from 'styled-components'

export const Container = styled.div`
padding: 1rem 3rem;
`

export const Action = styled.div`
  width: 80vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`

export const Title = styled.h1`
  font-size:2.8rem;
  line-height: 3rem;  
  padding: 2rem 2rem;  
  font-weight: 700;
  margin-bottom: 1rem;
  color: #fff;  
  text-align: center;


`
