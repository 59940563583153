import styled, { css } from 'styled-components';

export const Label = styled.label`
  ${({ theme }) => css`
    font-size: ${theme.forms.label.fontSize};
    padding: ${theme.forms.label.padding};
  `}
`;

export const Icon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 2rem;
  color: #809fb8;

  display: flex;
  align-items: center;
`;

export const FieldWrapper = styled.div`
  position: relative;
`;

export const TextField = styled.input`
  ${({ theme, error }) => css`
    border-radius: 18px;
    background: #0e242d;
    border-radius: 35px;
    padding: 2rem 3.5rem;
    font-size: 16px;
    font-weight: ${theme.forms.fontWeight};
    width: 100%;
    transition: all ${theme.transition} linear;
    color: #ffff;

    &::placeholder {
      color: #ffff;
    }

    &:focus,
    &:active,
    &:not(&:placeholder-shown) {
      background: #0e242d;
      transition: all ${theme.transition} linear;
    }
  `}
`;

export const ShowPassword = styled.div`
  position: absolute;
  right: 2.6rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  cursor: pointer;
  color: #fff;
`;

export const Error = styled.p`
  ${({ theme }) => css`
    font-size: 0.8em;
    position: absolute;
    bottom: 1em;
    right: 2em;
    color: #fff;
    background-color: ${theme.forms.error};
    padding: 0.5em 1em;
    border-radius: 1em;
  `}
`;

export const Container = styled.div`
  ${({ theme, styling, icon }) => css`
    display: ${theme.forms.wrapper.display};
    flex-direction: ${theme.forms.wrapper.flexDirection};
    gap: ${theme.forms.wrapper.gap};
    position: relative;
    width: 100%;
    border: none;

    ${styling !== 'default' &&
    css`
      ${Label} {
        position: absolute;
        font-size: 16px;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        left: 3.5rem;
        border: none !important;
        color: #809fb8;
        transition: ${theme.transition};

        left: ${icon ? '3.5rem' : '1rem'};
      }

      ${TextField} {
        background: #0e242d;
        padding-left: ${icon ? '4.8rem' : '3.5rem'};
        border: none !important;
        &::placeholder {
          color: transparent;
        }

        &:not(&:placeholder-shown),
        &:active,
        &:focus {
          padding: 2.5rem 3.5rem 1.5rem 4.8rem;
          padding-left: ${icon ? '4.8rem' : '3.5rem'};
          border: none !important;
          & + ${Label} {
            top: 2rem;
            left: ${icon ? '3.4rem' : '1.9rem'};
            font-size: 12px;
            color: #ffffff40;
            transition: ${theme.transition};
          }
        }
      }
    `}
  `}
`;
