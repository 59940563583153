import Account from '../pages/Account';
import AccountSettings from '../pages/Account/AccountSettings';
import Documents from '../pages/Account/Documents';
import FAQ from '../pages/Account/FAQ';
import Orders from '../pages/Account/Orders';
import OrderOpen from '../pages/Account/Orders/OrderOpen';
import AuthPage from '../pages/Auth';
import Cart from '../pages/Cart';
import ConfirmAccount from '../pages/ConfirmAccount';
import FaceDetectionPage from '../pages/FaceDetection';
import ForgotPassword from '../pages/ForgotPassword';
import NotFound from '../pages/NotFound';
import Payment from '../pages/Payment';
import PaymentSuccess from '../pages/PaymentSuccess';
import PaymentSucessInfo from '../pages/PaymentSucessInfo';
import Profile from '../pages/Profile';
import Signup from '../pages/Signup';
import TermsAndConditions from '../pages/TermsAndConditions';
import Welcome from '../pages/Welcome';
import DarkTemplate from '../templates/DarkTemplate';
import MainTemplate from '../templates/MainTemplate';

const routes = [
  {
    path: '/',
    isPublic: true,
    template: DarkTemplate,
    page: Welcome,
    title: 'Welcome',
  },
  {
    path: '/profile',
    isPublic: true,
    template: DarkTemplate,
    page: Profile,
    title: 'Profile',
  },
  {
    path: '/register',
    isPublic: true,
    template: DarkTemplate,
    page: Signup,
    title: 'Register',
  },
  {
    path: '/face-detection',
    isPublic: true,
    template: DarkTemplate,
    page: FaceDetectionPage,
    title: 'FaceDetection',
  },
  {
    path: '/terms-and-conditions',
    isPublic: true,
    template: DarkTemplate,
    page: TermsAndConditions,
    title: 'TermsAndConditions',
  },
  {
    path: '/cart',
    isPublic: true,
    template: DarkTemplate,
    page: Cart,
    title: 'Cart',
  },
  {
    path: '/payment/:id',
    isPublic: true,
    template: DarkTemplate,
    page: Payment,
    title: 'Payment',
  },
  {
    path: '/*',
    isPublic: true,
    template: MainTemplate,
    page: NotFound,
    title: 'Not Found',
  },

  {
    path: '/account',
    isPublic: true,
    template: DarkTemplate,
    page: Account,
    title: 'Account',
  },
  {
    path: '/account/settings',
    isPublic: true,
    template: DarkTemplate,
    page: AccountSettings,
    title: 'AccountSettings',
  },

  {
    path: '/account/documents',
    isPublic: true,
    template: DarkTemplate,
    page: Documents,
    title: 'Seus documentos',
  },

  {
    path: '/account/faq',
    isPublic: true,
    template: DarkTemplate,
    page: FAQ,
    title: 'Dúvidas frequentes',
  },

  {
    path: '/account/orders',
    isPublic: true,
    template: DarkTemplate,
    page: Orders,
    title: 'Seus pedidos',
  },

  {
    path: '/account/orders/:id',
    isPublic: true,
    template: DarkTemplate,
    page: OrderOpen,
    title: 'Seus pedidos',
  },

  {
    path: '/confirmation-code',
    isPublic: true,
    template: DarkTemplate,
    page: ConfirmAccount,
    title: 'Confirme sua conta',
  },

  {
    path: '/forgot-password',
    isPublic: true,
    template: DarkTemplate,
    page: ForgotPassword,
    title: 'Confirme sua conta',
  },

  {
    path: '/payment/sucess',
    isPublic: true,
    template: DarkTemplate,
    page: PaymentSuccess,
    title: 'Confirme sua conta',
  },

  {
    path: '/payment/sucess/id',
    isPublic: true,
    template: DarkTemplate,
    page: PaymentSucessInfo,
    title: 'Confirme sua conta',
  },

  {
    path: '/face-detection',
    isPublic: true,
    template: DarkTemplate,
    page: FaceDetectionPage,
    title: 'FaceDetection',
  },

  {
    path: '/auth/:sharedToken',
    isPublic: true,
    template: DarkTemplate,
    page: AuthPage,
    title: 'Login compartilhado',
  }
];

export default routes;
