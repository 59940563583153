// index.js

import React, { useRef } from 'react';
import * as S from './styles';

const Documents = ({ user, handleUploadProcuration }) => {
  const file = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleUploadProcuration(file);
  };

  return (
    <S.DocumentsContainer>
      {[
        {
          title: 'Fale com um médico',
          action: null,
        },
        {
          title: 'Laudo Médico',
          action: '/images/bluemed/download.svg',
        },
        {
          title: 'Receita Médica',
          action: '/images/bluemed/download.svg',
        },
        {
          title: 'Procuração',
          action: '/images/bluemed/upload-white.svg',
        },
        {
          title: 'Licença Anvisa',
          action: '/images/bluemed/download.svg',
        },
      ].map((doc, index) => (
        <S.Document key={index} done={user.meetDoctor}>
          <S.DocumentPosition>{index + 1}</S.DocumentPosition>
          <S.DocumentTitle>{doc.title}</S.DocumentTitle>
          <S.DocumentAction>
            {doc.action && (
              <S.DocumentButton>
                <S.DocumentIcon src={doc.action} /> Baixar
              </S.DocumentButton>
            )}
            {!doc.action && (
              <React.Fragment>
                <input
                  type='file'
                  style={{ display: 'none' }}
                  ref={file}
                  onChange={handleFileChange}
                />
                <S.DocumentButton onClick={() => file?.current.click()}>
                  <S.DocumentIcon src='/images/bluemed/upload-white.svg' />{' '}
                  Enviar
                </S.DocumentButton>
              </React.Fragment>
            )}
          </S.DocumentAction>
        </S.Document>
      ))}
    </S.DocumentsContainer>
  );
};

export default Documents;
