import { TbWorldQuestion } from 'react-icons/tb';
import Header from '../../../components/Header';
import Questions from '../../../components/Questions';
import * as S from './styles';

const FAQ = () => {
  return (
    <>
      <Header />
      <S.Container>
        <S.Title>
          <TbWorldQuestion />
          Dúvidas frequentes
        </S.Title>

        <Questions />
      </S.Container>
    </>
  );
};

export default FAQ;
