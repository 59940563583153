import { useCallback, useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Feedbacks from "../../components/Feedbacks";
import { API_URL_MIDDLEWARE } from "../../env";
import { httpService } from "../../services/axios";
import generateUUID from "../../utils/generateUUID";
import * as S from "./styles";

const paymentProvider = {
    card: "stripe",
    pix: "asaas",
};

const PaymentSuccess = () => {
    const [queryParams] = useSearchParams();
    const { method } = useParams();
    const navigate = useNavigate();
    const isAnamneseUpdated = useRef(false);

    const payment_intent = queryParams.get("payment_intent");
    const payment_intent_client_secret = queryParams.get(
        "payment_intent_client_secret"
    );
    const redirect_status = queryParams.get("redirect_status");

    const handleUpdateAnamnese = useCallback(async () => {
        try {
            const anamnesesId = localStorage.getItem("anamnesesId");

            await httpService.put(
                `${API_URL_MIDDLEWARE}/anamneses/${anamnesesId}`,
                {
                    paymentId: payment_intent,
                    paymentClient: payment_intent_client_secret,
                    paymentProvider: paymentProvider[method],
                }
            );

            await new Promise((resolve) => setTimeout(resolve, 3000));

            const uuid = generateUUID();
            navigate(`/medical-appointment/${uuid}`);
        } catch (error) {
            console.error("Error updating anamnese:", error);
        }
    }, [navigate, method, payment_intent, payment_intent_client_secret]);

    useEffect(() => {
        if (redirect_status === "succeeded" && !isAnamneseUpdated.current) {
            handleUpdateAnamnese();
            isAnamneseUpdated.current = true;
            setTimeout(() => {
                navigate(`/payment/success/${payment_intent}`);
            }, 1000);
        }
    }, [redirect_status, handleUpdateAnamnese, navigate, payment_intent]);

    return (
        <S.Container>
            <Feedbacks
                show={true}
                type="tertiary"
                title="Pagamento Aprovado"
                description={() => (
                    <>
                        Aguarde enquanto te redirecionamos <br /> para falar com
                        nossos médicos.
                    </>
                )}
            />
        </S.Container>
    );
};

export default PaymentSuccess;
