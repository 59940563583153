import { AiOutlineProduct } from 'react-icons/ai';
import Header from '../../../../components/Header';
import Summary from '../../../../components/Summary';
import * as S from './styles';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { httpService } from '../../../../services/axios';
import { useNavigate, useParams } from 'react-router';

const OrderOpen = () => {
  const cep = '12345-678';
  const endereco = 'Rua Exemplo, 123, Bairro, Cidade - Estado';
  const frete = 50; 

  const [order, setOrder] = useState();
  const navigate = useNavigate();
  const {id} = useParams();

  useEffect(() => {
    getOrder();
  }, []);

  const getOrder = async () => {
    try {
      Swal.fire({
        icon: 'info',
        title: 'Carregando...',
        text: 'Estamos buscando o produto',
        showConfirmButton: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        willOpen: () => {
            Swal.showLoading();
        },
      });

      const { data } = await httpService.get(`/orders/${id}`);
      let objectOrder = {id: data.id, ...data.attributes};
      let totalProducts = 0;
      for (const product of objectOrder.products) {
        totalProducts += (product.price * product.quantity);
      }
      objectOrder = {...objectOrder, totalProducts}
      setOrder(objectOrder);

      Swal.close();

    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: 'info',
        title: 'Oops!',
        text: 'Ocorreu um erro ao resgatar o produto, caso o erro persista entre em contato com o suporte.',
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/account/orders`);
        }
      });
    }

  };
  return (
    <>
      <Header />
      {order && <S.Container>
        <S.Title>
          <AiOutlineProduct />
          Pedido
        </S.Title>
        <Summary order={order} cep={cep} endereco={endereco} frete={frete} />
      </S.Container>}
    </>
  );
};

export default OrderOpen;
