import styled from 'styled-components';
import Button from '../../components/Button';

export const Container = styled.div``;

export const Footer = styled.div`
  padding: 10px 20px;
  background-color: #262d2b;
  border-radius: 20px 20px 0px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const Text = styled.p``;
export const TextInput = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1em 0 0.5em;

  border-bottom: 1px solid #585858;

  ${Text} {
    font-size: 1.4rem;
    color: #fff;
    font-weight: bold;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 0 1rem;
  gap: 0.4rem;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 1.2rem;
  width: 100%;

  ${Text} {
    font-size: 1.4rem;
    color: #fff;
  }
`;


export const RowCep = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${Text} {
    font-size: 1.4rem;
    color: #fff;
  }
`;

export const RowInputPrimeiroEndereco = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${Text} {
    font-size: 1.4rem;
    color: #fff;
  }
`;
export const RowInputSegundoEndereco = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${Text} {
    font-size: 1.4rem;
    color: #fff;
  }
`;

export const RowButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;

  ${Text} {
    font-size: 1.4rem;
    color: #fff;
  }
`;
export const Address = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const Input = styled.input`
  background: #4a5451;
  min-height: 55px;
  border: none;
  border-radius: 1.3rem;
  padding: 1.5rem 1.7rem;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 600;
  width: 100%;
`;

export const UploadButton = styled.label`
  background: transparent;
  border: none;
  max-height: 55px;
  border-radius: 1.3rem;
  padding: 1.5rem 1.7rem;
  border: 2px solid #4a5451;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-between;

  align-items: center;
  cursor: pointer;

  input {
    display: none;
  }
`;

export const UploadIcon = styled.span`
  background: url('/images/upload-icon.svg') no-repeat center center;
  width: 2rem;
  height: 2rem;
  display: inline-block;
  margin-left: 1rem;
`;

export const Actions = styled.div`
  display: flex;
  gap: 1.7rem;

  button {
    background-color: #4a5451;
    border-radius: 1.3rem;
    border: none;
    padding: 1.5rem 1.7rem;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
`;

export const AddressLabel = styled.p`
  color: #fff;
  font-size: 1.4rem;
`

export const ChangeAddress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  color: #fff;
  border: 2px solid #4a5451;
  border-radius: 1.3rem;
  padding: 1.5rem 1.7rem;
  font-size: 1.6rem;
`