import { rgba } from 'polished'
import styled from 'styled-components'

export const Container = styled.div`
  color: #fff;

  text-align: center;
  padding-top: 7rem;
  padding-bottom: 3rem;
`

export const Logo = styled.img.attrs({ src: '/images/bluemed/logo-inline-whiteen.svg' })`
  margin-bottom: 6.6rem;
`

export const Title = styled.h1`
  text-align: center;
  line-height: 0.8;
  font-size: 2.4rem;
  margin-bottom: 2.5rem;
`


export const Marker = styled.span`
  color: #28E8FC;
  font-weight: bold;
`

export const Dots = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2.5rem;
`

export const Dot = styled.div`
  background-color: #28E8FC;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
`

export const Content = styled.div`
  padding: 0 3.5rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;

`

export const Paragraph = styled.p`
  color: #D6D6D6;
  font-size: 1.5rem;
  line-height: 1.3;
  white-space: pre-line;
`

export const Checkbox = styled.label`
  ${({ checked }) => !checked && `opacity: 0.5`};

  border: 2px solid #28e8fc;
  border-radius: 1.8rem;

  padding: 3rem 3.5rem;

  display: flex;
  gap: 1.5rem;
  background-color: ${rgba('#28e8fc', 0.1)};

`

export const CheckboxInput = styled.input`
  appearance: none;
  width: 2.6rem;
  height: 2.6rem;
  background-color: transparent;
  border: 1px solid #28e8fc;
  border-radius: 6px;
  cursor: pointer;

  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 2px;
    left: 2px;
    transition: transform 0.3s;
  }

  &:checked:before {
    transform: translateX(26px); / Mover o círculo branco para a direita
  }

  &:checked:after {
    content: '\\2714';
    position: absolute;
    left: 0.8rem;
    top: 0.4rem;
    color: #28e8fc;
    font-size: 1.4rem;
    transition: opacity 0.3s;
    opacity: 0;
  }

  &:not(:checked):after {
    opacity: 0;
  }

  &:checked:after {
    opacity: 1;
  }
`

export const CheckboxLabel = styled.span`
  border-bottom: 1px solid #28e8fc;
  color: #28e8fc;
  font-size: 1.4rem;
`

export const Actions = styled.div`
  display: flex;
  gap: 2rem;
  ${({ checked }) => !checked && `opacity: 0.5`};

  button {
    background: transparent linear-gradient(180deg, #00E7FF 0%, #0089FF 100%) 0% 0% no-repeat padding-box;
    font-size: 1.5rem;
    padding: 3.7rem 2rem;
  }
`

export const Icon = styled.img.attrs({ src: '/images/bluemed/marijuana.svg' })`
  margin-left: 2rem;
`
