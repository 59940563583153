import React from 'react';
import { CiLogout } from 'react-icons/ci';
import { GoQuestion } from 'react-icons/go';
import { IoCalendarClearOutline } from 'react-icons/io5';
import { LuUser2 } from 'react-icons/lu';
import { RxDashboard } from 'react-icons/rx';

import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Header from '../../components/Header/';

import { PROVIDER_NAME, REACT_APP_BLUEMED_FRONT, TOKEN_NAME } from '../../env';
import * as S from './styles';
import { BsCart } from 'react-icons/bs';

const Profile = () => {
  const navigate = useNavigate();

  const handleSettingsClick = () => {
    window.location.href = REACT_APP_BLUEMED_FRONT  
  };


  const handleAppointmentsClick = () => {
    navigate('/account/orders');
  };

  const handleFaqClick = () => {
    navigate('/account/faq');
  };

  const handleLogoutClick = () => {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(PROVIDER_NAME);
    navigate('/');
  };

  return (
    <>
      <Header />
      <S.Container>
        <S.Menu>
          <Button
            variant='grey'
            size='large'
            block
            onClick={handleSettingsClick}
          >
            Meu Perfil
            <LuUser2 />
          </Button>


          <Button
            variant='grey'
            size='large'
            block
            onClick={() => navigate('/profile')}
          >
            Acessar Loja
            <BsCart />
          </Button>

          <Button
            variant='grey'
            size='large'
            block
            onClick={handleAppointmentsClick}
          >
            Pedidos
            <IoCalendarClearOutline />
          </Button>

          <Button variant='grey' size='large' block onClick={handleFaqClick}>
            Dúvidas frequentes
            <GoQuestion />
          </Button>

          <Button variant='grey' size='large' block onClick={handleLogoutClick}>
            Fazer logout
            <CiLogout />
          </Button>
        </S.Menu>
      </S.Container>
    </>
  );
};

export default Profile;
