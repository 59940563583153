import Button from "../../../../components/Button";
import { cartStore } from "../../../../store/cart.store";
import { formatCurrency } from "../../../../utils/formatCurrency";
import * as S from "./styles";

const ProductList = () => {
    const { cart, removeFromCart, updateQuantity } = cartStore();

    const handleGoToShop = () => {
        window.location.href = "/profile"
    };

    if(!cart.length) {
        return (
            <S.EmptyCart>
                <S.EmptyCartText>Seu carrinho está vazio</S.EmptyCartText>
                
                <Button onClick={handleGoToShop} variant="blue">Ir para a loja</Button>
            </S.EmptyCart>
        )
    }
    return (
        <S.Container>
            <S.Items>
                {cart.map((item) => (
                    <S.Item key={item.id}>
                        <S.ItemCover>
                            <S.ItemImage
                                src={
                                    item?.medias?.data?.[0]?.attributes?.url
                                        ? item?.medias?.data?.[0]?.attributes
                                              ?.url
                                        : "/images/products/oilimage.jpg"
                                }
                            />
                        </S.ItemCover>
                        <S.ItemWrap>
                            <S.ItemHeader>
                                <S.ItemTitle>{item.title}</S.ItemTitle>
                                <S.ItemAction
                                    onClick={() => removeFromCart(item)}
                                >
                                    Excluir
                                </S.ItemAction>
                            </S.ItemHeader>

                            <S.ItemPrice>
                                <S.ItemLeft>
                                    <S.Select value={item.quantity} onChange={(e) => updateQuantity(item, e.currentTarget.value)}>
                                        {
                                            Array.from(
                                                { length: item.maxQuantity },
                                                (_, i) => i + 1
                                            ).map((quantity) => (
                                                <option
                                                    key={quantity}
                                                    value={quantity}
                                                >
                                                    {quantity}
                                                </option>
                                            ))
                                        }
                                    </S.Select>
                                </S.ItemLeft>
                                <S.ItemRight>
                                    <S.Price>
                                        {formatCurrency(
                                            item.price * item.quantity
                                        )}
                                    </S.Price>
                                </S.ItemRight>
                            </S.ItemPrice>
                        </S.ItemWrap>
                    </S.Item>
                ))}
            </S.Items>
        </S.Container>
    );
};

export default ProductList;
