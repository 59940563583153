import styled from 'styled-components';

export const Container = styled.div`
  padding: 4em;
`;

export const Title = styled.div`
  color: #fff;
  margin-bottom: 1em;
  font-size: 23px;
  line-height: 15px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5em;
`;

export const Icon = styled.img`
  height: 22px;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;
