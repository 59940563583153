import { useCallback, useEffect, useState } from "react";
import { httpService } from "../../services/axios";
import CardOrPix from "../../components/CardOrPix";
import Header from "../../components/Header";
import * as S from "./styles";
import SummaryPayment from "../../components/SummaryPayment";
import { cartStore } from "../../store/cart.store";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";

let interval = null

const Payment = () => {
    const { id } = useParams();
    const [order, setOrder] = useState({});
    const [qrCodePix, setQrCodePix] = useState();
    const [sqalaPaymentId, setSqalaPaymentId] = useState();
    const navigate = useNavigate();

    const { cart, countItems, total, clearCart  } = cartStore();

    const [frete] = useState(120);

    async function getOrder() {
        try {
            const response = await httpService.get(`/orders/${id}`);

            setOrder({
                id,
                ...response.data.attributes
            })

            return {
                status: 1,
                message: 'ok'
            }
        } catch (error) {
            navigate('/profile')
        }
    }

    async function getPaymentPix() {
        if ((Object.keys(order).length > 0)) {
            try {
                Swal.fire({
                    icon: 'info',
                    title: 'Carregando...',
                    text: 'Gerando QR Code.',
                    showConfirmButton: false,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    willOpen: () => {
                        Swal.showLoading();
                    },
                });

                let response = await httpService.get(`/orders/${order.code}/sqala/getpayment`);

                let newOrder = { ...order };
                newOrder.sqalaPaymentId = response.data.id;
                setOrder(newOrder)
                setSqalaPaymentId(response.data.id)
                setQrCodePix(response.data.payload)
            } catch (error) {
                Swal.fire('Oops!', 'Não foi possível gerar o QR Code.', 'error');
            } finally {
                Swal.close();
            }
        }
    }

    useEffect(() => {
        if (!id) {
            navigate('/profile');
        }

        getOrder();
    }, []);

    useEffect(() => {
        if (!qrCodePix || !sqalaPaymentId) {
            getPaymentPix();
        }
    }, [order])

    const handlePayment = async () => {
        try {
            const purchase = await httpService.post("/generate-purchase", {
                cart,
            });

            const res = await httpService.post("/complete-payment", {
                amount: total,
                purchase: purchase.id,
            });

            document.location.href = res.data.url;
        } catch (error) {
            console.error("Erro ao processar pagamento:", error);
        }
    };

    const handleCheckPayment = useCallback(async () => {
        try {
            const response = await httpService.get(`/orders/check/${order.id}`);
            if (response.data) {
                clearInterval(interval)

                await Swal.fire({
                    icon: 'success',
                    title: 'Pagamento aprovado!',
                    text: 'Seu pedido foi aprovado com sucesso. Em breve você receberá um e-mail com as informações de entrega.',
                    showConfirmButton: true
                });

                clearCart()

                navigate(`/account/orders/${order.id}`)

            }
        } catch (error) {
            console.log(error)
        }
    }, [order])

    useEffect(() => {
        if (order.id) {
            handleCheckPayment();

            interval = setInterval(() => {
                handleCheckPayment();
            }, 5000)
        }

        return () => {
            clearInterval(interval)
        }
    }, [order])

    return (
        <>
            <Header showUser={false} />
            <S.Container>
                <SummaryPayment
                    cart={cart}
                    frete={frete}
                    amount={order?.value}
                    cep={order?.sendTo?.cep}
                    quant={countItems()}
                />
                <CardOrPix onPayment={handlePayment} codePix={qrCodePix} />
            </S.Container>
        </>
    );
};

export default Payment;
