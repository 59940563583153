import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ show, fullPage }) => css`
    transition: 0.3s;
    position: fixed;
    bottom: -100%;
    width: 100%;
    background: white linear-gradient(180deg, #0b192d 0%, #061920 100%) 0% 0%
      no-repeat padding-box;
    transition: 1s;
    visibility: hidden;
    z-index: -1;
    overflow: auto;
    padding-bottom: 2rem;

    ${show &&
    css`
      z-index: 2;
      visibility: visible;
      bottom: 0;
      height: 100%;
      transition: 0.5s;

      ${!fullPage &&
      css`
        padding-top: 10rem;
        background: linear-gradient(180deg, #0b192d 0%, #061920 100%);
      `}
    `}
  `}
`;

export const Container = styled.div`
  ${({ fullPage }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 80%;
    margin: 0 auto;
    position: relative;
    padding-top: ${fullPage ? '32rem' : '5rem'};

    ${!fullPage &&
    css`
      border-radius: 27px 27px 0px 0px;
      box-shadow: 0px -3px 45px #88a2af5c;
      width: 100%;
      height: 100%;
      padding-left: 10%;
      padding-right: 10%;
    `}
  `}
`;

export const Group = styled.div`
  ${({ show }) => css`
    display: flex;
    flex-direction: column;
    padding: 4rem 5rem;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #061920;
    box-shadow: 0px 9px 21px #06192090;
    border-radius: 0px 0px 45px 45px;
    z-index: 1;
    top: -400%;
    opacity: 0;
    transition: 0.1s;

    ${show &&
    css`
      top: 0;
      opacity: 1;
      transition: 0.5s;
    `}
  `}
`;

export const TitleGroup = styled.h1`
  ${({ theme }) => css`
    background: linear-gradient(172deg, #fff 0%, #f2f2f2 100%) no-repeat;
    background-size: cover;
    background-position: 0% 0%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-align: center;
    font-size: 3.7rem;
    line-height: 2.8rem;
    font-weight: normal;
    font-family: ${theme.font.bold};
    box-sizing: content-box;
    padding-top: 1rem;
    padding-bottom: 1rem;
  `}
`;

export const DescriptionGroup = styled.p`
  ${({ theme }) => css`
    font-size: 1.8rem;
    line-height: 1.3rem;
    font-weight: normal;
    color: ${theme.colors.gray};
    font-family: ${theme.font.regular};
  `}
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
`;

export const CloseHeader = styled.div`
  position: absolute;
  top: 3rem;
  right: 0rem;
  cursor: pointer;
`;

export const Title = styled.p`
  ${({ theme }) => css`
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.3rem;
    color: ${theme.colors.gray};
    font-family: ${theme.font.regular};
  `}
`;

export const Socials = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
`;

export const ActionTitle = styled.p`
  ${({ theme }) => css`
    text-align: center;
    font-size: 2.3rem;
    color: ${theme.colors.gray};
    font-family: ${theme.font.regular};
  `}
`;

export const Icons = styled.div`
  display: flex;
  gap: 2.5rem;
`;

export const Icon = styled.div`
  ${({ icon }) => css`
    background-image: url(${`/images/icon/${icon}.svg`});
    background-color: #fff;
    box-shadow: 0px 7px 9px #114d8929;
    border-radius: 100%;
    width: 6.5rem;
    height: 6.5rem;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 2.5rem;
  `}
`;

export const Form = styled.div`
  padding: 4rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${ActionTitle} {
    position: relative;

    &::before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: calc(30vw);
      height: 0.1rem;
      background-color: #dfedf4;
    }

    &:after {
      right: 0;
    }

    &:before {
      left: 0;
    }
  }
`;

export const Fields = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ForgotPassword = styled.p`
  text-align: right;
  font-size: 1.5rem;
  text-decoration: underline;
  color: #7b7b7b;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const Actions = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;

  button {
    flex: 1;
  }
`;

export const Close = styled.div`
  background-color: #dfedf4;
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 100%;
  background-image: url('/images/icon/arrow-bottom-blue.svg');
  background-repeat: no-repeat;
  background-position: center center;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  color: #a3a3a3;
  font-size: 1.5rem;
  gap: 0.5rem;
`;

export const SignUp = styled.p`
  color: #646464;
  text-decoration: underline;
  font-size: 1.5rem;
`;
