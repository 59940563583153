import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { httpService } from '../../services/axios';

function useMe() {
  const [me, setMe] = useState({});
  const navigate = useNavigate();
  const handleGetMeInfo = async () => {
    const { data } = await httpService.get(`/users/profile`);
    setMe(data); 
  }

  useEffect(() => {
    handleGetMeInfo();
  }, [navigate])

  return me;
}

export default useMe;
