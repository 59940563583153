import { object, ref, string } from "yup";
import { httpService } from "../../../../../services/axios";
import { API_URL_MIDDLEWARE } from "../../../../../env";
import * as yup from "yup";

const isEmailTaken = async (email) => {
  try {
    const { data } = await httpService.get(
      `${API_URL_MIDDLEWARE}/users/${email}`
    );
    return !!data;
  } catch (error) {
    return false;
  }
};

export const Step1Schema = object({
  email: string()
    .email("Insira um email válido")
    .max(100, "Email muito longo...")
    .required("Oops, parece que você não adicionou um email..."),
  name: string()
    .max(150, "Nome muito longo...")
    .required("Oops, parece que você não adicionou um nome..."),
  password: yup
    .string()
    .min(5, "Humm, a senha está muito curta...")
    .max(30, "Senha muito longa...")
    .required("Oops, parece que você não adicionou a sua senha..."),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "As senhas não coincidem")
    .required("Você precisa confirmar a senha"),
  whatsapp: yup
    .string()
    .required(
      "Oops, parece que você não adicionou um número para o whatsapp..."
    ),
});
