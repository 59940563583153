import React from 'react';
import QRCode from 'qrcode.react';

const PixQRCode = ({ pixCode }) => {
  return (
    <div>
      <QRCode 
        value={pixCode}
        fgColor={'#fff'} 
        bgColor={'black'} 
      />
    </div>
  );
};

export default PixQRCode;
