// CardOrPix.js
import { useState } from "react";
import Button from "../Button";
import * as S from "./styles";
import PixQrCode from "../../components/PixQrCode";
import Swal from "sweetalert2";

const CardOrPix = ({ onPayment, codePix }) => {
  const [method, setMethod] = useState('pix');

  const handleMethod = (method) => {
    setMethod(method);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (method === 'card') {
      onPayment('card');
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).catch(err => {
      console.error("Erro ao copiar o texto: ", err);
    });
    
    Swal.fire({
      icon: 'success',
      title: 'Código copiado',
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <>
      <S.Container>
        {/* <S.Choose>
          <S.ChooseItem
            active={method === 'card'}
            onClick={() => handleMethod('card')}
          >
            <S.ChooseBg>
              <S.ChooseIcon
                icon={`card${method === 'pix' ? '-gray' : ''}`}
                width='3.4rem'
                height='2.7rem'
              />
            </S.ChooseBg> 
            <S.ChooseTitle>Cartão de crédito</S.ChooseTitle>
          </S.ChooseItem> 

          <S.ChooseItem
            active={method === 'pix'}
            onClick={() => handleMethod('pix')}
          >
            <S.ChooseBg>
              <S.ChooseIcon
                icon={`pix${method === 'card' ? '-gray' : ''}`}
                width='4.1rem'
                height='4.1rem'
              />
            </S.ChooseBg>
            <S.ChooseTitle>PIX</S.ChooseTitle>
          </S.ChooseItem>
        </S.Choose> */}

        <S.Form onSubmit={handleSubmit}>
          {method === 'card' && (
            <Button type='submit'
            variant='blue'
            >Pagar com cartão</Button>
          )}

          {(method === 'pix' && codePix) && (
            <S.QrCodeContainer>
              <PixQrCode pixCode={codePix}/>
              <S.PixCode>{codePix}</S.PixCode>
              <S.ContainerButton>
                <S.ButtonCopy type="button" onClick={() => handleCopy(codePix)}>
                  Copiar código pix
                </S.ButtonCopy>
              </S.ContainerButton>
            </S.QrCodeContainer>
          )}

          <S.Disclaimer>
            Ao confirmar sua subscrição, <br /> você permite que <b>Bluemed®</b>{' '}
            cobre seu cartão <br /> por esse pagamento e pagamentos futuros{' '}
            <br /> de acordo com as condições.
          </S.Disclaimer>
        </S.Form>
      </S.Container>

    </>
  );
};

export default CardOrPix;
