import React from 'react';
import Header from '../../components/Header';
import Products from '../../components/Products';
import * as S from './styles';

function Profile() {
  return (
    <S.Container>
      <Header />
      <Products />
    </S.Container>
  );
}

export default Profile;
