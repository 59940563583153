import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { AiOutlineRight } from 'react-icons/ai';
import { IoReturnUpBackOutline } from 'react-icons/io5';

import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import * as S from '../../styles';
import { Step2Schema } from './schema';

import { STEPS } from '../steps';

const Step2 = ({ handleNext, value, setValueStep }) => {
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(Step2Schema),
    defaultValues: value,
  });

  const { register, getValues } = methods;
  const errors = methods.formState.errors;

  const submit = useCallback(
    (values) => {
      setValueStep(values);
      handleNext(STEPS.STEP_3, { ...value, ...values });
    },
    [value],
  );
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(submit)}>
        <S.Group>
          <S.TitleGroup>
            <b>2.</b> Dados Pessoais
          </S.TitleGroup>
          <Input
            styling='secondary'
            label='RG'
            placeholder='rg'
            {...register('rg')}
            name='rg'
            maxLength={11}
            error={errors.rg?.message}
          />

          <Input
            styling='secondary'
            label='CPF'
            placeholder='cpf'
            {...register('cpf')}
            name='cpf'
            mask='999.999.999-99'
            error={errors.cpf?.message}
          />

          <Input
            styling='secondary'
            label='Data de Nascimento'
            placeholder='Data de Nascimento'
            {...register('dateOfBirth')}
            name='dateOfBirth'
            mask='99/99/9999'
            error={errors.dateOfBirth?.message}
          />

          <S.Document {...register('gender')}>
            <option value=''>Qual é seu sexo?</option>
            <option value='masculino'>Masculino</option>
            <option value='feminino'>Feminino</option>
          </S.Document>
          {errors.gender?.message}

          <S.ButtonWrapper>
            <Button
              type='button'
              variant='whiteBackground'
              onClick={() => handleNext(1)}
            >
              <IoReturnUpBackOutline />
              Retornar
            </Button>
            <Button size='small' variant='blue'>
              Próximo
              <AiOutlineRight />
            </Button>
          </S.ButtonWrapper>
        </S.Group>
      </form>
    </FormProvider>
  );
};

export default Step2;
