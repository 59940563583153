import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #1b2025;
  border-radius: 24px;
`;

export const Container = styled.div`
  background-color: #111715;
  padding: 2.4rem;
  border-radius: 2.4rem 2.4rem 1rem;
  position: relative;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1.3rem;
`;

export const Figure = styled.div`

  padding: 1.2rem 2rem;
  height: 90px;
  width: 120px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  border-radius: 2rem;

`;

export const Image = styled.img`
  position: relative;
  height: 90px;
  width: 120px;
  z-index: 2;
  object-fit: contain;
`;

export const Info = styled.div`
  text-align: left;
  text-transform: uppercase;
  font-weight: 600;
`;

export const InfoTitle = styled.p`
  color: #fff;
  font-size: 2.4rem;
`;

export const InfoDescription = styled.p`
  color: #28e8fc;
  font-size: 2rem;
`;

export const Emojis = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const Emoji = styled.img``;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Right = styled.div``;

export const Left = styled.div``;

export const Ratings = styled.div`
  display: flex;
  gap: 0.3rem;
`;

export const Title = styled.h1`
  font-size: 3.3rem;
  text-transform: uppercase;
  color: #fff;
`;

export const Marker = styled.span`
  color: #28e8fc;
`;

export const THC = styled.div`
  border: 4px solid #28e8fc;
  border-radius: 100%;
  width: 5rem;
  height: 5rem;

  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  &:before {
    content: 'THC';

    position: absolute;
    top: -1.4rem;
    left: 0;
    transform: translateX(-50%);

    background-color: #373d3b;
    padding: 0.3rem 0.6rem;
    border-radius: 0.7rem;
    border: 4px solid #111715;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;

  button {
    font-size: 1.8rem;
    font-weight: 600;
    width: 100%;

    &:first-of-type {
      border-color: #111715;
      color: #d6d6d6;
    }

    &:not(&:first-of-type) {
      background: transparent linear-gradient(180deg, #00e7ff 0%, #0089ff 100%)
        0% 0% no-repeat padding-box;
      justify-content: center;
    }
  }

  svg {
    font-size: 2rem;
  }
`;

export const Detail = styled.div``;

export const DetailTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 4rem;
  padding-right: 2rem;
`;

export const DetailTitle = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
`;

export const DetailClose = styled.div`
  background-color: #161d1b;
  border-radius: 1.2rem;
  padding: 1rem 2.5rem;
`;

export const Sizes = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;

  gap: 2rem;
`;

export const Variant = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  text-align: center;
`;

export const VariantTag = styled.div`
  color: #28e8fc;
  background-color: #3e4948;
  font-size: 1rem;
  border-radius: 4px;
  text-align: center;
  padding: 0.3rem;
  font-weight: bold;
  width: fit-content;
  margin: 0 auto -1rem auto;
  position: relative;
  font-weight: 600;
`;

export const VariantPrice = styled.div`
  color: #28e8fc;
  background-color: #111816;
  text-shadow: 0px 0px 15px #28e8fcb9;
  font-size: 1.6rem;
  font-weight: 600;

  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  border-radius: 1.5rem 1.5rem 0 0;
`;

export const VariantTitle = styled.div`
  color: #fff;
  background-color: #1a1f1d;

  padding: 2rem;
  font-size: 1.6rem;
  border-radius: 0 0 1.5rem 1.5rem;

  display: flex;
  justify-content: center;
  gap: 0.5rem;
`;

export const VariantIcon = styled.img.attrs({
  src: '/images/bluemed/weight.svg',
})`
  height: 1.5rem;
  opacity: 0.5;
`;

export const Content = styled.div``;

export const ContentDescription = styled.div`
  padding: 2rem 3rem;

  color: #d6d6d6;
  font-size: 2rem;
  text-align: justify;
`;

export const ContentSensations = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding: 2rem 1.5rem;
`;

export const ContentTitle = styled.div`
  font-size: 1.8rem;
  color: #fff;
  font-weight: bold;
  margin-left: 2.7rem;
`;

export const SensationItem = styled.div`
  background: #1a1f1d;
  border-radius: 30px;
`;

export const SensationIcon = styled.img``;

export const SensationProgress = styled.div`
  background: transparent linear-gradient(267deg, #00e7ff 0%, #0089ff 100%) 0%
    0%;
  box-shadow: 0px 3px 40px #28e8fc5c;
  border-radius: 30px;
  padding: 0.5rem 1rem;

  display: flex;
  align-items: center;

  gap: 1rem;
`;

export const SensationTitle = styled.p`
  color: #00ffeb;
  text-shadow: 0px 0px 15px #28e8fc;
  font-size: 1.2rem;
`;

export const ButtonAddCart = styled.div`
  background: #0000006e 0% 0%;
  border: 2px solid #111715;
  border-radius: 17px;
  margin-top: 2rem;
  font-size: 1.4rem;
  color: #d6d6d6;
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  gap: 1rem;

  cursor: pointer;
`;

export const ButtonAddCartText = styled.p`
  font-size: 1.4rem;
  color: #d6d6d6;
  margin-left: 0;
  margin-right: auto;
`;

export const SymptomsBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 2rem;
`;

export const SymptomsTitle = styled.div`
  color: #fff;
  font-size: 21px;
  border-top: 1px solid #ffffff10;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  font-weight: bold;
`;

export const Symptom = styled.div`
  display: flex;
  background: #1a1f1d;
  color: #fff;
  font-size: 18px;
  gap: 1.5rem;
`;

export const SymptomsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const InfoPrice = styled.div`
  font-size: 2.5rem;
  color: #fff;
`;

export const InfoOriginalPrice = styled.div`
  color: #ffffff70;
  font-size: 1.6rem;
  text-decoration: line-through;
`;

export const InlineContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: start;
  flex-direction: column;
  gap: 1.5rem;
`;

export const InfoVolume = styled.div`
  color: #fff;
  font-size: 1.5rem;
  margin: 0;
`;

export const PriceBox = styled.div`
  display: flex;
  z-index: 30;
  width: 120px;

  padding: 0.3rem;
  bottom: 0;
  flex-direction: column;
  gap: 0.3rem;

  align-items: flex-start;
  justify-content: flex-start;
`;

export const BuyLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 1rem;

  @media (max-width: 320px) {
    font-size: 14px;
  }
`;
