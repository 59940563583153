import { useCallback, useEffect, useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import { MdOutlineFileUpload } from "react-icons/md";
import { RiShieldCheckLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Header from "../../components/Header";
import ProductList from "./components/ProductList";
import * as S from "./styles";
import { formatCurrency } from "../../utils/formatCurrency";
import { cartStore } from "../../store/cart.store";
import { httpService } from "../../services/axios";
import Swal from "sweetalert2";
import useProfileInfo from "../../hooks/useMe";

const Cart = () => {
    const profile = useProfileInfo()
    const { cart, removeFromCart, total, loading } = cartStore();
    const [showAddress, setShowAddress] = useState(false);

    const [cep, setCep] = useState("");
    const [endereco, setEndereco] = useState("");
    const [textUpload, setTextUpload] = useState("Comprovante de Residência");
    const [numero, setNumero] = useState("");
    const [file, setFile] = useState({});
    const [enderecoObj, setEnderecoObj] = useState({
        logradouro: '',
        localidade: '',
        bairro: '',
        uf: '',
    });
    const [frete] = useState(120);
    const [isCepComplete, setIsCepComplete] = useState(false);
    const [isDocumentVerified, setIsDocumentVerified] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // Simulando uma chamada à API dos Correios para obter o endereço completo com base no CEP
        const getEnderecoByCep = async () => {
            try {
                const response = await fetch(
                    `https://viacep.com.br/ws/${cep}/json/`
                );
                const data = await response.json();
                if (!data.erro) {
                    setEndereco(
                        `${data.logradouro}, ${data.localidade} - ${data.uf}`
                    );

                    setEnderecoObj({
                        logradouro: data.logradouro,
                        localidade: data.localidade,
                        bairro: data.bairro,
                        uf: data.uf,
                    })
                }
            } catch (error) {
                console.error("Erro ao obter endereço:", error);
            }
        };

        if (cep.length === 8) {
            getEnderecoByCep();
        }
    }, [cep]);

    useEffect(() => {
        let validation = true;

        for (const key in enderecoObj) {
            if (enderecoObj[key] == '') {
                validation = false;
                break;
            }
        }

        const isComplete = validation && numero !== '' && cep !== ''
        setIsCepComplete(isComplete)
    }, [enderecoObj, numero, cep, isDocumentVerified])

    const handleChangeEndereco = (filed, value) => {
        let enderecoNew = { ...enderecoObj };
        enderecoNew[filed] = value;
        setEnderecoObj(enderecoNew);
    }

    const handleFile = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        if (file) {
            setTextUpload(file.name);
            setFile(event.target.files[0]);
        }

        setIsDocumentVerified(true);
    };

    const prepareValuesToCreateOrder = useCallback(() => {
        let objectToSend = {
            products: [],
            cep: '',
            street: '',
            number: '',
            neighborhood: '',
            city: '',
            state: '',
            complement: '',
        };

        for (const product of cart) {
            objectToSend.products.push({
                productId: product.id,
                quantity: parseInt(product.quantity)
            });
        }

        objectToSend.cep = cep;
        objectToSend.street = enderecoObj.logradouro;
        objectToSend.number = numero;
        objectToSend.neighborhood = enderecoObj.bairro;
        objectToSend.city = enderecoObj.localidade;
        objectToSend.state = enderecoObj.uf;
        objectToSend.prescriptionId = cart[0].prescriptionId;

        return objectToSend;
    }, [cart, cep, enderecoObj, numero])

    const handleCheckout = async () => {
        try {
            if (cart.length === 0) {
                Swal.fire('Oops!', 'Adicione produtos ao carrinho para finalizar a compra.', 'info');
                return;
            }
    
            if (!isCepComplete) {
                Swal.fire('Oops!', 'Preencha todos os campos do endereço.', 'info');
                return;
            }
    
            if(!isDocumentVerified) {
                Swal.fire('Oops!', 'Adicione o comprovante de endereço.', 'info');
                return;
            }
    
    
            if (isCepComplete && isDocumentVerified) {
                const object = prepareValuesToCreateOrder();
    
                Swal.fire({
                    title: 'Aguarde...',
                    text: 'Estamos cadastrando seu pedido.',
                    allowOutsideClick: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });
                
                const response = await httpService.post('/orders', { ...object, paymentMethod: 'pix' });
    
                await httpService.patch(`/orders/${response.data.id}/proof-of-residence`, { file }, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
    
                Swal.close();
    
                return navigate(`/payment/${response.data.id}`);
            }
        } catch (error) {
            Swal.fire('Oops!', 'Ocorreu um erro ao finalizar a compra. Tente novamente.', 'error');
        }
    };

    useEffect(() => {
        if (profile?.address) {
            const { cep, street, number, neighborhood, city, state } = profile.address
            setCep(cep)
            setEndereco(`${street}, ${number}, ${neighborhood} - ${city} - ${state}`)
            setNumero(number)
            setEnderecoObj({
                logradouro: street,
                localidade: city,
                bairro: neighborhood,
                uf: state,
            })
        }
    }, [profile])

    useEffect(() => {
        if (numero) {
            setEndereco(`${enderecoObj.logradouro}, ${numero}, ${enderecoObj.bairro} - ${enderecoObj.localidade} - ${enderecoObj.uf}`)
        }
    }, [numero, enderecoObj])
    return (
        <>
            <S.Container>
                <Header showUser={false} />
                <ProductList cart={cart} removeItem={removeFromCart} />
                { !!cart?.length && (
                    <S.Footer>
                        <S.Header>
                            <S.Text>Total Compra</S.Text>
                            <S.Text>{loading ? 'Calculando total' : formatCurrency(total + 120)}</S.Text>
                        </S.Header>

                        <S.Body>
                            <S.Row>
                                <S.Text>Produtos ({cart.length})</S.Text>
                                <S.Text>{loading ? 'Calculando preço' : formatCurrency(total)}</S.Text>
                            </S.Row>

                            <S.Row>
                                <S.Text>Envios</S.Text>
                                <S.Text>{loading ? 'Calculando frete' : formatCurrency(frete)}</S.Text>
                            </S.Row>

                            <S.Row>
                                <S.UploadButton>
                                    <S.TextInput>
                                        <MdOutlineFileUpload size={32} />
                                        {textUpload}
                                    </S.TextInput>
                                    <input
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={(event) => handleFile(event)}
                                    />

                                    {isDocumentVerified && <RiShieldCheckLine />}
                                </S.UploadButton>
                            </S.Row>

                            <S.AddressLabel>
                                {endereco}
                            </S.AddressLabel>

                            <S.ChangeAddress onClick={() => setShowAddress(!showAddress)}>Alterar endereço</S.ChangeAddress>

                            {
                                showAddress && (
                                    <>
                                        <S.Row>
                                            <S.RowCep>
                                                <S.Input
                                                    type="text"
                                                    value={cep}
                                                    placeholder="CEP"
                                                    onChange={(e) => setCep(e.target.value)}
                                                />
                                            </S.RowCep>
                                            <S.RowInputPrimeiroEndereco>
                                                <S.Input
                                                    type="text"
                                                    value={enderecoObj.logradouro}
                                                    placeholder="Rua"
                                                    onChange={(e) => handleChangeEndereco('logradouro', e.target.value)}
                                                />
                                            </S.RowInputPrimeiroEndereco>
                                            <S.RowInputPrimeiroEndereco>
                                                <S.Input
                                                    type="number"
                                                    step='1'
                                                    value={numero}
                                                    placeholder="Número"
                                                    onChange={(e) => setNumero(e.target.value)}
                                                />
                                            </S.RowInputPrimeiroEndereco>
                                            <S.RowInputPrimeiroEndereco>
                                                <S.Input
                                                    type="text"
                                                    value={enderecoObj.bairro}
                                                    placeholder="Bairro"
                                                    onChange={(e) => handleChangeEndereco('bairro', e.target.value)}
                                                />
                                            </S.RowInputPrimeiroEndereco>
                                        </S.Row>
                                        <S.Row>
                                            <S.RowInputSegundoEndereco>
                                                <S.Input
                                                    type="text"
                                                    value={enderecoObj.localidade}
                                                    placeholder="Cidade"
                                                    onChange={(e) => handleChangeEndereco('cidade', e.target.value)}
                                                />
                                            </S.RowInputSegundoEndereco>
                                            <S.RowInputSegundoEndereco>
                                                <S.Input
                                                    type="text"
                                                    value={enderecoObj.uf}
                                                    placeholder="Estado"
                                                    onChange={(e) => handleChangeEndereco('uf', e.target.value)}
                                                />
                                            </S.RowInputSegundoEndereco>
                                        </S.Row>
                                    </>
                                )
                            }
                        </S.Body>

                        <S.Actions>
                            <Button
                                onClick={() => navigate("/profile")}
                                variant="secondary"
                                style={{ width: "40%" }}
                            >
                                <GoArrowLeft />
                                Voltar
                            </Button>
                            <Button
                                onClick={handleCheckout}
                                isLoading={false}
                                isLoadingMessage="Finalizando Compra..."
                                variant="blue"
                                style={{ width: "100%" }}
                            >
                                Finalizar Compra <RiShieldCheckLine />
                            </Button>
                        </S.Actions>
                    </S.Footer>
                ) }
            </S.Container>
        </>
    );
};

export default Cart;
